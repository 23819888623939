import React from "react";
import { Route } from "react-router-dom";

// components
import { RouteType } from "@/apps/common/components/RoutesHandler";
import MemberTimeline from "@/apps/common/pages/Timeline/Timeline";
import Register from "@/apps/common/pages/auth/Register";
import PrivateRoute from "@/apps/common/routes/PrivateRoute";
import { default as ProviderLayout } from "../components/Layout";
import Billing from "../pages/Billing/Billing";
import NewReferral from "../../common/pages/NewReferral/NewReferral";
import Profile from "../pages/Profile/Profile";
import PaymentStatus from "@/apps/clinic/pages/PaymentStatus/PaymentStatus";
import RescheduleAppointmentPage from "../pages/RescheduleAppointment/RescheduleAppointmentPage";
import ResetPassword from "@/apps/common/pages/auth/ResetPassword";
import ForgetPassword from "@/apps/common/pages/auth/ForgetPassword";

import AppointmentReport from "../pages/AppointmentReport/AppointmentReport";
import Availability from "../pages/Availability/Availability";
import Referrals from "../pages/Referrals/Referrals";

// auth
const Login = React.lazy(() => import("@/apps/common/pages/auth/Login"));
const Logout = React.lazy(() => import("@/apps/common/pages/auth/Logout"));
const Appointments = React.lazy(
  () => import("../pages/Appointments/Appointments")
);

// dashboards
const dashboardRoutes: RouteType[] = [
  {
    path: "/appointments",
    name: "Appointments",
    element: (
      <ProviderLayout>
        <Appointments />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/appointments/:id",
    name: "Appointments",
    element: (
      <ProviderLayout>
        <AppointmentReport />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/appointments/:id/schedule",
    name: "AppointmentSchedule",
    element: (
      <ProviderLayout>
        <RescheduleAppointmentPage mode="new" />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/appointments/:id/reschedule",
    name: "AppointmentReschedule",
    element: (
      <ProviderLayout>
        <RescheduleAppointmentPage mode="reschedule" />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/my-account",
    name: "MyAccount",
    element: (
      <ProviderLayout>
        <Profile />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/availability",
    name: "Availability",
    element: (
      <ProviderLayout>
        <Availability />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/referrals",
    name: "Referrals",
    element: (
      <ProviderLayout>
        <Referrals />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/referrals/:id",
    name: "MemberTimeline",
    element: (
      <ProviderLayout>
        <MemberTimeline />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/referrals/new-referrals",
    name: "NewReferral",
    element: (
      <ProviderLayout>
        <NewReferral />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/billing",
    name: "Billing",
    element: (
      <ProviderLayout>
        <Billing />
      </ProviderLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/payment-method-status",
    name: "PaymentStatus",
    element: <PaymentStatus />,
    route: PrivateRoute
  }
];
// auth
const authRoutes: RouteType[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route
  },
  {
    path: "/auth/reset-password",
    name: "Reset Password",
    element: <ResetPassword />,
    route: Route
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route
  }
];
export const defaultRoute = "/appointments";

export const authProtectedRoutes = [...dashboardRoutes];
export const publicRoutes = [...authRoutes];

export { menuItems } from "./menuItems";
