import { PortalTypes } from "@/constants/portals";
import { LegacyUserType } from "../api-client/api-client.types";
import { TimeSlotType } from "../types/appointment-types";

export function getPortalType() {
  if (window.location.hostname.startsWith("clinic.")) {
    return PortalTypes.CLINICAL;
  } else if (window.location.hostname.startsWith("provider.")) {
    return PortalTypes.PROVIDER;
  } else if (window.location.hostname.startsWith("member.")) {
    return PortalTypes.MEMBER;
  } else if (window.location.hostname.startsWith("admin.")) {
    return PortalTypes.ADMIN;
  } else if (window.location.hostname.startsWith("connectbenefit.") || 
             window.location.hostname.startsWith("aitherhealth.") ||
             window.location.hostname.startsWith("5gbenefits.")) {
    return PortalTypes.PARTNER;    
  }

  return PortalTypes.MEMBER;
}

export function getPartnerName() {
  if (window.location.hostname.startsWith("connectbenefit.")){
    return "Connects Benefit";    
  }
  if (window.location.hostname.startsWith("aitherhealth.")){
    return "Aither Health";    
  }
  if (window.location.hostname.startsWith("5gbenefits.")){
    return "5G Benefits";    
  }
}


export function getPortalTitle() {
  const portalType = getPortalType();
  if (portalType === PortalTypes.CLINICAL) {
    return "Clinical Portal";
  } else if (portalType === PortalTypes.PROVIDER) {
    return "Provider Portal";
  } else if (portalType === PortalTypes.MEMBER) {
    return "Member Portal";
  } else if (portalType === PortalTypes.ADMIN) {
    return "Admin Portal";
  } else if (portalType === PortalTypes.PARTNER) {
    return "Partner Portal";
  }

  throw new Error("Unknown portal type");
}

export function getPortalLink(portalType: PortalTypes) {
  return window.location.href.replace(getPortalType(), portalType);
}

export function getFullName(
  user?: Pick<LegacyUserType, "firstName" | "lastName" | "email">
) {
  if (!user) {
    return "";
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.firstName) {
    return user.firstName;
  } else if (user.lastName) {
    return user.lastName;
  }

  return user.email;
}

export function transformTimeSlots(
  timeSlots: TimeSlotType[],
  forProvider = false
) {
  return timeSlots.map((timeSlot) => {
    const data = {
      id: timeSlot.id.toString(),
      title: "Click here to confirm",
      start: timeSlot.startDate,
      end: timeSlot.endDate,
      type: timeSlot.type,
      className: "bg-success"
    };

    if (forProvider) {
      if (timeSlot.available) {
        data.title = "Available";
        data.className = "bg-primary";

        if (timeSlot.type == "tentative") {
          data.className = "bg-warning";
          data.title = "Tentative";
        }
      } else {
        data.title = "Booked";
      }
    } else {
      if ((timeSlot as any).sessionDuration === 60) {
        data.title = "60 minutes";
      } else {
        data.title = "30 minutes";
      }
    }

    return data;
  });
}
