import { useEffect, useState } from "react";
import {
  PaymentProfile,
  PaymentMethod
} from "@/apps/common/types/appointment-types";
import { api } from "@/apps/common/api-client";
import Table from "@/apps/common/components/Table";
import { Card, Button, Spinner, Toast } from "react-bootstrap";
import PaymentModal from "@/apps/common/components/PaymentModal";
import { useCommonStore } from "@/apps/common/store/useCommonStore";
import Alert from 'react-bootstrap/Alert';
// import { IconName } from "react-icons/bs";
// import { CiWarning } from "react-icons/ci";
import { FaCcVisa } from "react-icons/fa6";
import { FaCreditCard } from "react-icons/fa";
import { FaCcMastercard } from "react-icons/fa";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function returnCreditCardIcon(cardType: string) {
  {
    // Need to add more cardType icons later
    if (cardType == "visa") {
      return (
        <span>
          <FaCcVisa fontSize={"2em"} />
        </span>
      );
    } else if (cardType === "mastercard") {
      return (
        <span>
          <FaCcMastercard fontSize={"2em"} />
        </span>
      );
    } else {
      return (
        <span>
          <FaCreditCard fontSize={"2em"} className={"mx-1"} />
          {cardType}
        </span>
      );
    }
  }
}
const PaymentMethods = ({ userType, userId = null}: { userType: string, userId:number|null|undefined }) => {
  const paymentMethodsCol = [
    {
      Header: "Payment type",
      accessor: "brand",
      sort: true,
      Cell: ({ value }: { value: string }) => returnCreditCardIcon(value)
    },
    {
      Header: "Last 4 digits",
      accessor: "last4",
      sort: true
    },
    {
      Header: "Expiration Month",
      accessor: "expMonth",
      sort: true,
      Cell: ({ value = "" }: { value: string }) => (
        <span>{month[parseInt(value) - 1]}</span>
      )
    },
    {
      Header: "Expiration Year",
      accessor: "expYear"
    },
    {
      Header: "Default Payment Method",
      accessor: "id",
      Cell: ({ value }: { value: number }) => (
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          checked={isDefaultPayment(value)}
          onChange={() => {
            setDefaultPaymentMethod(value);
          }}
        ></input>
      )
    },
    {
      id: "action",
      Header: "Delete Payment Details",
      accessor: "id",
      Cell: ({ value }: { value: string }) => (
        <Button
          size="sm"
          variant="btn btn-primary rounded-pill width-sm"
          onClick={() => deletePaymentMethod(value)}
        >
          Delete
        </Button>
      )
    }
  ];

    const [paymentProfile, setPaymentProfile] = useState<PaymentProfile | undefined>()
    const [show, setShow] = useState<boolean>(false)
    const [showAlerts, setShowAlerts] = useState<boolean>(false)

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
    const [paymentAlert, setPaymentAlert] = useState<string>()
    const [alertType, setAlertType] = useState<string>('warning')
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
    const { addPaymentMethod } = useCommonStore();
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

    function paymentAlerts() {
        return (
            <Alert variant={alertType} onClose={() => setShowAlerts(false)} dismissible>
                
                <p>
                {paymentAlert}
                </p>
            </Alert>
        );
    }

    const addPayment = async () => {
      setShowAlerts(false)
        try {
            setPaymentLoading(true);
            //const { data } = await addPaymentMethod();
            let _data;
            console.debug ('userId ' + userId)
            if (userId)
            _data = {
              data : {
              user: userId
              }
            }
            else
              _data = null

            const { data } = await api.payments.addPaymentMethod(_data);
            location.replace(data.url);
            setPaymentLoading(false);
        } catch (err) {
            console.log(err);
            setPaymentLoading(false);
        }
    };

  const dismissMemberPayment = () => {
    console.debug("dismissPayment");
    setShowPaymentModal(false);
  };

    const deletePaymentMethod = (id: string) => {

      setShowAlerts(false)
      setShowAlerts(false)

        // Make sure the member has no active member services when deleting all payment methods.
        console.debug('paymentmethods ' + JSON.stringify(paymentMethods))

    if (paymentMethods.length === 1) {
      if (userType == "member") {
        api.memberServices
          .findMany()
          .then((res) => res[0])
          .then((res) => {
            console.debug(JSON.stringify(res));
            if (res.status !== "completed" && res.status !== "archive") {
              setPaymentAlert(
                "You have an upcoming scheduled appointment with a counselor which requires a credit card for payment. In order to delete this credit card, please add another credit card first and set it as your default payment option."
              );
              setShowAlerts(false);
              setShowAlerts(true);
              //toast.error('You have an upcomming service which requires payment. In order to delete this Credit Card, please add another and set is as default');
              //setPaymentToastMsg('You have an upcomming service which requires payment. In order to delete this Credit Card, please add another and set is as default')
              //setShowPaymentToast(true)
              return;
            } else {
              setShow(true);
              api.payments.deletePaymentMethod(id);
              getPaymentProfile();
              return;
            }
          });
        // async call does not wait for api to return member services.
        return;
      } else {
        //setPaymentToastMsg('In order to delete this Credit Card, please add another and set is as default')
        //setShowPaymentToast(true)
        setPaymentAlert(
          "Please select another credit card as default first. If you do not have another credit card, then please add another credit first and then select it as your default payment option."
        );
        setShowAlerts(false);
        setShowAlerts(true);
        return;
      }
    }

    for (const paymentMethod of paymentMethods) {
      if (paymentMethod.id == parseInt(id) && paymentMethod.default == true) {
        //setPaymentToastMsg('Please select or add another Credit Card as default first.');
        //setShowPaymentToast(true)
        setPaymentAlert(
          "Please select another credit card as default first. If you do not have another credit card, then please add another credit first and then select it as your default payment option."
        );
        setShowAlerts(false);
        setShowAlerts(true);
        return;
      }
    }

    if (paymentMethods.length === 2) {
      for (const paymentMethod of paymentMethods) {
        //console.debug ('paymentMethod.id - ' + paymentMethod.id + ' id ' + id)
        if (paymentMethod.id != parseInt(id)) {
          api.payments
            .updatePaymentMethod(paymentMethod.id.toString(), { default: true })
            .then((res) => {
              // const _paymentMethods = JSON.parse(JSON.stringify(paymentMethods));
              // for (const [index, paymentMethod] of _paymentMethods.entries()) {
              //   if (paymentMethod.id != parseInt(id)){
              //     _paymentMethods[index].default = true
              //   }
              // }
              // setPaymentMethods(_paymentMethods)
            });
        }
      }
    }

        setShow(true)
        setShowAlerts(false)
        api.payments.deletePaymentMethod(id).then(() => {
            const _paymentMethods = []
            for (const [index, paymentMethod] of paymentMethods.entries()) {
                if (paymentMethod.id != parseInt(id))
                    _paymentMethods.push(paymentMethod)

            }
            setPaymentMethods(_paymentMethods)
        }
        )
        setShow(false)
        getPaymentProfile()


    }

  function setDefaultPaymentMethod(id: number) {
    setShow(true)
    setShowAlerts(false)

    let toggleDefault = false;

    //console.debug('setDefaultPaymentMethod - ' + id)

    const _paymentMethods = JSON.parse(JSON.stringify(paymentMethods));

    for (const [index, paymentMethod] of _paymentMethods.entries()) {

      console.debug('processing - ' + paymentMethod.id)
      if (paymentMethod.id === id && paymentMethod.default === true) {
        setPaymentAlert('Please select another credit card as default first. If you do not have another credit card, then please add another credit first and then select it as your default payment option.');
        setShowAlerts(false)
        setShowAlerts(true)
        //setPaymentToastMsg('Please select or add another Credit Card as default first.');
        //setShowPaymentToast(true)
        console.debug('No Toggling')
      }

      else if (paymentMethod.id == id && paymentMethod.default == false) {
        console.debug('Toggling true')
        _paymentMethods[index].default = true
        toggleDefault = true
      }

      else if (paymentMethod.default == true) {
        console.debug('Toggling false')
        _paymentMethods[index].default = false
        toggleDefault = true
      }

    }

    setPaymentMethods(_paymentMethods)

    if (toggleDefault == true) {

      for (const [index, paymentMethod] of _paymentMethods.entries()) {
        api.payments.updatePaymentMethod(paymentMethod.id.toString(), { default: paymentMethod.default }).then(
          ((res) => {
            console.debug('updatePaymentMethod success' + JSON.stringify(res.data))
            setPaymentAlert('Your default Credit Card has been successfuly updated.');
            setAlertType('success')
            setShowAlerts(false)
            setShowAlerts(true)

          }),
          (
            (err) => {
              console.debug('updatePaymentMethod error' + JSON.stringify(err.data))
              setPaymentAlert('An error occurred while updating your default Credit Card.');
              setAlertType('warning')
              setShowAlerts(false)
              setShowAlerts(true)
            }
          )
        )

      }
    }

    setShow(false)

  }

  function isDefaultPayment(id: number) {
    //console.debug('isDefaultPayment - ' + id)
    let defaultVal = false;
    for (const paymentMethod of paymentMethods) {
      if (paymentMethod.id == id) {
        defaultVal = paymentMethod.default;
        break;
      }
    }
    //console.log('isDefaultPayment - ' + id.toString() + ' val ' + defaultVal)
    return defaultVal;
  }

  const getPaymentProfile = () => {
    
    let paymentProfile;
    api.payments.getPaymentProfile(userId).then((res) => {
      //console.debug("paymentProfile - " + JSON.stringify(res));
      paymentProfile = res;
      setPaymentProfile(paymentProfile);
      //console.log('paymentMethods - ' + JSON.stringify(res.paymentMethods?.data))
      const _paymentMethods: PaymentMethod[] = [];
      res?.paymentMethods?.data.forEach((paymentMethod: any) => {
        //console.debug(JSON.stringify(paymentMethod))
        if (paymentMethod.attributes.active == true)
          _paymentMethods.push({
            id: paymentMethod.id,
            stripeId: paymentMethod.attributes.stripeId,
            brand: paymentMethod.attributes.brand,
            last4: paymentMethod.attributes.last4,
            default: paymentMethod.attributes.default,
            expMonth: paymentMethod.attributes.expMonth,
            expYear: paymentMethod.attributes.expYear
          });
        setPaymentMethods(_paymentMethods);
        setShow(false);
      });
    });
  };

  useEffect(getPaymentProfile, []);
  return (
    <>
      <Card className="mt-2">
        {show ? (
          <div className="text-center p-4 mt-4">
            <Spinner
              style={{ width: 100, height: 100 }}
              animation="border"
              variant="info"
            />
          </div>
        ) : null}

        {paymentMethods.length > 0 ? (
          <Card.Body>
            <div className="text-center mt-2">
              <h4 className="mb-2 fs-15">Credit Card Payment Information</h4>
              <div className="table-responsive">
                {showAlerts ? paymentAlerts() : null}
                <Card.Body>
                  <Table
                    columns={paymentMethodsCol}
                    data={paymentMethods}
                    //pageSize={10}
                    isSortable={true}
                  />
                </Card.Body>
              </div>
            </div>
          </Card.Body>
        ) : (
          <div className="text-center mt-2">
            <h4 className="mb-2 fs-15">No Credit Card Payment Information</h4>
          </div>
        )}
        <Card.Body>
          <div className="text-center mt-2 table-responsive">
            <Button
              onClick={() => {
                setShowPaymentModal(true);
              }}
            >
              {" "}
              Add New Credit Card
            </Button>
          </div>
        </Card.Body>
      </Card>
      <PaymentModal
        loading={paymentLoading}
        show={showPaymentModal}
        onClick={addPayment}
        onHide={dismissMemberPayment}
      />
    </>
  );
};

export default PaymentMethods;
