import { AxiosInstance } from "axios";
import { unstrapiMany } from "../../helpers/strapi";
import { UserSettingsType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

export class UserSettingsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api/user-settings");
  }

  getByUserId(id: number) {
    return this.client
      .get(this.buildPath(["by-user", String(id)]), {
        params: {
          filters: { user: { $eq: id } }
        }
      })
      .then((res) => res.data as UserSettingsType);
  }

  async find() {
    return await this.client
      .get(this.buildPath())
      .then(
        (res) => unstrapiMany<UserSettingsType>(res.data, "userSettings")[0]
      );
  }

  update(id: number, data: Omit<UserSettingsType, "id">) {
    return this.client.put(this.buildPath(String(id)), { data });
  }
}
