import { Button, Modal } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { getPortalType } from "@/apps/common/helpers";
import { PortalTypes } from "@/constants/portals";

interface PaymentModalProps {
  show: boolean;
  loading: boolean;
  onClick: () => void;
  onHide?: () => void;
  serviceType?: string;
}

const CLINICAL_MESSAGE = (
  <>
    Please add a valid credit card. The credit card will not be charged at this
    time. We will only charge your credit card when a report has been
    successfully delivered to the clinic. Skyler Health will send an email
    receipt to you when we charge your credit card for a report. We will also
    indicate in the Clinic Portal when a charge has been paid.
  </>
);

const MEMBER_MESSAGE = (
  <div className="text-start">
    <strong>Private Pay:</strong> Our private pay rate is $99 for 30 minutes and $135 for 1 hour appointments.
    Please add a valid credit card. This credit card will not be charged at this
    time. We will charge your credit card $70 when you make an appointment to
    hold your appointment. And the final $29 (for 30 minutes appointments) or
    $65 (for 1 hour appointments) will be charged to your card after your
    session and when a session report has been successfully delivered.
    <br />
    <br />
    <strong>Cancellation Policy Private Pay:</strong> You can cancel or reschedule for no charge if your appointment 
    is over 8 hours away. If you are within 8 hours of your appointment, 
    then the $70 hold payment that was charged when you had scheduled this 
    prior appointment will not be refunded and cannot be applied towards your rescheduled appointment 
    as this prior appointment is within 8 hours of the current time. When you select a new appointment time, 
    you will be charged $99 (30 minutes) or $135 (60 minutes) again for the new appointment.
    <br />
    <br />
    <strong>Health Insurance:</strong>If you are paying using your health insurance, 
    then we will charge your credit card for your co-pay only. 
    We process your health insurance through our partner Headway.
    <br />
    <br />
    <strong>Cancellation Policy Health Insurance:</strong>You can cancel or reschedule for no charge if your 
    appointment is over 8 hours away. If you are within 8 hours of your appointment, 
    then we will charge your credit card $70 for cancellation fee as we have to pay the 
    counselors for their appointment loss.
    <br />
    <br />
    <strong>No Show Policy:</strong>If you are a no show for your appointment, 
    then your credit card on file will be charged $70 for a no show fee.
    <br />
    <br />
    Skyler Health will send an email receipt to your email when we charge your credit card. 
    You will also see the charge in the Billing section of the Member Portal. 
    Upon your request, we can provide you with a bill that you can submit to your health 
    insurance company to get paid for your out-of-network benefits for this appointment.
    <br />
    Contact us at{" "}
    <a href="mailto:care@skylerhealth.com" target="_blank">
      care@skylerhealth.com
    </a>
  </div>
);

const MEMBER_COACHING_MESSAGE = (
  <div className="text-start">
    <strong>Private Pay:</strong> Our private pay rate for coaching is $40 for 30 minutes and $80 for 1 hour appointments 
    paid in advance when you make the appointment. 
    Please add a valid credit card.
    <br />
    <br />
    <strong>Cancellation Policy Private Pay:</strong> You can cancel or reschedule for no charge if your appointment is 
    over 8 hours away. If you are within 8 hours of your appointment, then the payment that was charged when you made the 
    existing appointment will not be refunded and cannot be applied towards your rescheduled new appointment as this prior 
    appointment is within 8 hours of the current time and we have to pay our coaches for the time they confirmed for your appointment.
    <br />
    <br />
    <strong>No Show Policy:</strong>We do not refund if you are a no show for your appointment.
    <br />
    <br />
    Skyler Health will send an email receipt to your email when we charge your credit card. 
    You will also see the charge in the Billing section of the Member Portal. 
    <br />
    Contact us at{" "}
    <a href="mailto:care@skylerhealth.com" target="_blank">
      care@skylerhealth.com
    </a>
  </div>
);

const PROVIDER_MESSAGE = (
  <>
    Please add a valid credit card. This credit card will not be charged at this
    time. We will only charge your credit card when a report has been
    successfully delivered. Skyler Health will send an email receipt to you when
    we charge your credit card for an assessment and report. We will also
    indicate the charge in your dashboard once it has been paid.
  </>
);

const ADMIN_MESSAGE = (
  <>
    Please add a valid credit card.
  </>
);


const PaymentModal = ({
  show,
  onClick,
  onHide,
  loading,
  serviceType = "therapy"
}: PaymentModalProps) => {

  console.debug (`Payment Modal ${serviceType}`)
  return (
    <Modal className="payment-modal" show={show} centered onHide={onHide}>
      <Modal.Header closeButton={!!onHide}>
        <h4 className="modal-title">Payment</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <FeatherIcon size="50px" icon="credit-card" />
          <p className="text-muted mt-2">
            {getPortalType() === PortalTypes.CLINICAL
              ? CLINICAL_MESSAGE
              : getPortalType() === PortalTypes.MEMBER 
                ? serviceType !== "coaching" ? MEMBER_MESSAGE : MEMBER_COACHING_MESSAGE: getPortalType() === PortalTypes.ADMIN ? ADMIN_MESSAGE
                : PROVIDER_MESSAGE}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={onClick} className="w-100">
          {loading ? "loading..." : "Add New Credit Card"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentModal;
