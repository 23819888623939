import { api } from "@/apps/common/api-client";
import HealthInsuranceForm from "@/apps/common/components/HealthInsurance/HealthInsurance";
import TeleHealthLocationForm from "@/apps/common/components/HealthInsurance/TeleHealthLocation";
import PaymentMethods from "@/apps/common/components/PaymentMethods";
import { APPOINTMENT_STATUS, PAYMENT_TYPE } from "@/apps/common/constants";
import { useRequest } from "ahooks";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import Invoice from "../../components/Invoice";
import { PageTitle } from "@/apps/common/components";
import { ConfirmModal } from "@/apps/common/components/ConfirmModal/ConfirmModal";
import Table from "@/apps/common/components/Table";
import MemberTimeline from "@/apps/common/pages/Timeline/TimelineProps";
import {
  AccountType,
  AppointmentType,
  CPTCodeType,
  EvaluationReportType,
  HealthInsuranceType,
  MemberEventType,
  MemberServiceType,
  PaymentLogType,
  ReferralType,
  ServiceProviderType,
  TelehealthLocationType,
  UserType
} from "@/apps/common/types/appointment-types";
import FeatherIcon from "feather-icons-react";
import { Alert, Badge } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useNavigate, useParams } from "react-router-dom";
import { cancelAppointment, isPastAppointment } from "./SchedulingApptUtils";
import MemberProfileForm from "@/apps/common/components/MemberProfile/MemberProfile";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Appointment from "../Appointment/Appointment";
import {showAlert} from "@/apps/common/helpers/alertUtils"
import MemberSettings from "./MemberSettings";

export default function MemberServiceDetails() {
  const [deepMemberService, setMemberService] = useState<MemberServiceType>();
  const [memberService, set_MemberService] = useState<MemberServiceType>();
  const [serviceProvider, setServiceProvider] = useState<ServiceProviderType>();
  const [memberEvents, setMemberEvents] = useState<MemberEventType[]>();
  const [referral, setReferral] = useState<ReferralType>();
  const [appointment, setAppointment] = useState<AppointmentType>();
  const [appointmenrError, setAppointmentError] = useState<boolean>(false);
  const [teleHealthLocationData, setTeleHealthLocationData] =
    useState<TelehealthLocationType>();
  const [healthInsurance, setHealthInsuranceData] =
    useState<HealthInsuranceType>();
  const [paymentLogs, setPaymentLogs] = useState<PaymentLogType[]>();
  const [user, setUser] = useState<UserType>();
  const [providerReport, setProviderReport] = useState<EvaluationReportType>();
  const [showPaymentTypeChangeConfirmDlg, setShowPaymentTypeChangeConfirmDlg] =
    useState<boolean>(false);
  const [copayAmount, setCopayAmount] = useState<number>(25.0);
  const [miscAmount, setMiscAmount] = useState<number>();
  const [miscAmountDescp, setMiscAmountDescp] = useState<string>();
  const [disableCopay, setDisableCopay] = useState<boolean>(false);
  const [copayMessage, setCopayMessage] = useState<string>();
  const [displayCopayAlert, setDisplayCopayAlert] = useState<boolean>(false);
  const [miscpayMessage, setMiscpayMessage] = useState<string>();
  const [displayMiscPayAlert, setDisplayMiscPayAlert] = useState<boolean>(false);
  const [invalidMiscAmount, setInvalidMiscAmount] = useState<boolean>(false);
  invalidMiscAmount
  //const [employerUpdateMessage, setEmployerUpdateMessage] = useState<string>();
  //const [displayEmployerUpdate, setDisplayEmployerUpdate] = useState<boolean>(false);  
  const [copayBtnText, setCopayBtnText] = useState<string>("Charge Copay");
  const [cancelApptDialog, setCancelApptDialog] = useState(false);
  const [showFollowUpTip, setShowFollowUpTip] = useState(false);
  const [showFollowUpTipMessage, setShowFollowUpTipMessage] =
    useState<string>();
  const [disableFollowUp, setDisableFollowUp] = useState(false);
  const [billingAccount, setBillingAccount] =
    useState<Partial<AccountType> | null>();
  const [allBillingAccounts, setAllBillingAccounts] = useState<
    Partial<AccountType>[]
  >([]);
  const [showBillingAccounts, setShowBillingAccounts] =
    useState<boolean>(false);
  const [selectedBillingAccount, setSelectedBillingAccount] =
    useState<number>();
  const [attachBillingAccount, setAttachBillingAccount] =
    useState<boolean>(true);
  const [displayNoteSaveMessage, setDisplayNoteSaveMessage] =
    useState<boolean>(false);
  const [noteSubmissionStatus, setNoteSubmissionStatus] = useState<string>();
  
  const target = useRef(null);

  const updateAdminFollowUp = (event: any) => {
    console.debug(event.currentTarget.checked);
    const checked = event.currentTarget.checked;

    setDisableFollowUp(true);
    const userData = {
      admin_follow_up: checked
    };

    updateUser(user!.id, userData).then(
      (res) => {
        setShowFollowUpTipMessage("Updated successfully - ");
        setShowFollowUpTip(true);
        //setUser(res)
        const timeoutId = setTimeout(() => {
          setShowFollowUpTip(false);
          setDisableFollowUp(false);
        }, 2000);
      },
      (err) => {
        setShowFollowUpTipMessage(
          "An error while trying to update. Please try again !"
        );
        setShowFollowUpTip(true);
        const timeoutId = setTimeout(() => {
          setShowFollowUpTip(false);
          setDisableFollowUp(false);
        }, 2000);
      }
    );
  };

  const deleteButton = [
    APPOINTMENT_STATUS.DUE,
    APPOINTMENT_STATUS.CONFIRMED,
    APPOINTMENT_STATUS.PENDING
  ].includes(appointment?.status ? appointment?.status : "") ? (
    <Button
      className="btn btn-sm btn-primary me-2 pe-1"
      onClick={() => {
        setCancelApptDialog(true);
      }}
    >
      <div className="d-flex align-items-center px-1">
        <FeatherIcon icon="trash" />
        <span className="mx-1">Cancel Appointment</span>
      </div>
    </Button>
  ) : null;

  function cancelModalMessage() {
    let apptExpired = false;

    if (!appointment?.timeSlot) return;

    const apptTime = new Date(appointment?.timeSlot?.startDate);
    if (!apptTime) {
      console.error("Appointment - " + JSON.stringify(appointment));
    }

    const now = new Date();

    if (apptTime && now.getTime() > apptTime.getTime()) apptExpired = true;

    if (apptExpired == true) {
      return (
        <>
          <p>
            You are trying to cancel an appointment that has already taken
            place. You cannot cancel this appointment.
          </p>
        </>
      );
    }

    if (appointment?.status === APPOINTMENT_STATUS.DUE) {
      return (
        <p>
          This will cancel the appointment and also delete the session. You will
          not be able to reuse the survey responses in the future. If you choose
          this option, there will no refund for the $70 hold payment that was
          charged when the appointment was scheduled since this appointment is
          within 8 hours of the current time.
        </p>
      );
    }

    return (
      <p>
        This will cancel the appointment and also delete the session. You will
        not be able to reuse your survey responses in the future. A refund for
        the $70 will be issued.
      </p>
    );
  }

  function confirmDelete() {
    return (
      <Modal
        show={cancelApptDialog}
        centered
        onHide={() => {
          setCancelApptDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">Confirm Delete</Modal.Title>
        </Modal.Header>

        <Modal.Body>{cancelModalMessage()}</Modal.Body>

        <Modal.Footer>
          {(appointment && isPastAppointment(appointment)) == false ? (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setCancelApptDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (appointment) cancelAppointment(appointment);
                }}
              >
                Confirm
              </Button>
            </>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                setCancelApptDialog(false);
              }}
            >
              Ok
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  type adminRowData = {
    note: string;
    createdAt: string;
  };

  const adminNotesColumns = [
    {
      Header: "Created At",
      accessor: "createdAt",
      sort: true
    },
    {
      Header: "Note",
      accessor: "note",
      sort: true
    }
  ];

  const [rows, setRows] = useState<adminRowData[]>([]);

  async function fetchAllAdminNotes() {
    if (user?.id)
      api.adminNotes.findAllNotes(user!.id.toString()).then((res) => {
        //console.debug('Response ' + JSON.stringify(res))
        setRows([]);
        res.forEach((x) => {
          //console.debug('serviceProvider - ' + JSON.stringify(x))

          const row = {
            id: x?.id,
            note: x?.note,
            followUp: x?.followUp ? "Yes" : "No",
            createdAt: new Date(x?.createdAt).toDateString()
          };

          setRows((prev) => [...prev, row]);
        });
        return res;
      });
  }

  const { id, refId } = useParams();
  //const [searchParams, setSearchParams] = useSearchParams();

  //console.debug('searchParams ' + JSON.stringify(Object.keys(searchParams)))

  console.debug("Id " + id);

  console.debug("refId " + refId);

  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          console.debug("user update succeeded - " + JSON.stringify(result));
        } catch (error) {
          console.debug("user update failed - " + JSON.stringify(error));
          console.error(error);
        }
      },
      manual: true
    }
  );

  const saveNote = (event: any) => {
    event.preventDefault();

    //console.debug('Saving AdminNote ...' + JSON.stringify(event.target.adminNotes.value))
    //console.debug('Saving Folloup Flag ...' + JSON.stringify(event.target.followUp.checked))
    if (!event.target.adminNotes.value) return;

    const data = {
      user: memberService?.user?.id,
      note: event.target.adminNotes.value
      //followUp: event.target.followUp.checked
    };
    api.adminNotes.create(data).then(
      (res) => {
        console.debug("Admin Note Saved successfully");

        setNoteSubmissionStatus("Admin Note was saved successfully");
        showAdminNoteAlert();
      },
      (err) => {
        console.debug("Error SavingAdmin Note");
        setNoteSubmissionStatus("Error savingAdmin Note");
        showAdminNoteAlert();
      }
    );
  };

  const { data: _adminNotes = [] } = useRequest(() => fetchAllAdminNotes(), {
    //refreshDeps: [filters],
    debounceWait: 3000,
    //pollingInterval: 200000,
    ready: !!user
  });

  const { data: _deepMemberService, runAsync: _fetchMemberService } = useRequest(() => fetchMemberService(), {
    refreshDeps: [selectedBillingAccount, billingAccount],
    debounceWait: 500
    //pollingInterval: 5000
  });

  //console.debug ('referral ' + JSON.stringify(referral))
  async function fetchMemberService() {
    //console.debug('fetchMemberService');

    if (id) {
      api.memberServices.findMemberService(parseInt(id)).then((res) => {
        //console.debug ('memberService ' + JSON.stringify(res))
        //setReRender(true)
        setMemberService(res);
        set_MemberService(res);

        //console.debug ('providerReport - ' + JSON.stringify(res?.providerReport) )
        setProviderReport(res?.providerReport);
        //console.debug ('User - ' + JSON.stringify(res?.user ))
        //unstrapiMany<MemberEventType>(res?.events, "memberEvent")
        setServiceProvider(res?.appointment?.timeSlot?.serviceProvider);
        setMemberEvents(res?.events);
        setReferral(res?.referral);
        setAppointment(res?.appointment);
        // Check for incosistent states between Appointment and Time Slot.
        if (
          (res?.appointment?.status === APPOINTMENT_STATUS.CONFIRMED ||
            res?.appointment?.status === APPOINTMENT_STATUS.DUE) &&
          res.appointment?.timeSlot.available === true
        ) {
          setAppointmentError(true);
        }

        //console.debug ( 'Referral - ' + JSON.stringify(res?.referral?.serviceProvider?.user))
        setTeleHealthLocationData(res?.user?.telehealth_location);
        setUser(res?.user);
        //console.debug('Health Insurance Data - ' + JSON.stringify(res?.user?.health_insurance))
        setHealthInsuranceData(res?.user?.health_insurance);
        setPaymentLogs(res?.paymentLogs);
        // paymentLogs?.forEach ((log) => {
        //     console.debug ('paymentLog - ' + JSON.stringify(log))
        // })
        //console.debug ('payment logs ' + JSON.stringify(res?.paymentLogs))
        const paymentLog = paymentLogs?.find(
          (log) => log.mode === "insurance-copay"
        );

        //console.debug (JSON.stringify(paymentLog))
        if (!paymentLog) {
          //console.debug ('Enablig copay since payment logs do not exist')
          setDisableCopay(false);
        } else {
          //console.debug ('Disabling copay since payment logs exists')
          setDisableCopay(true);
        }

        // Fetch Invoice Accounts associated with this user

        const args = {
          populate: ["users"],
          filters: {
            users: {
              $in: [res?.user?.id]
            }
          }
        };

        const userId = res?.user?.id;

        if (userId) {
          api.accounts.fetchAllAccounts(args).then(
            (res) => {
              if (!res?.length || res?.length === 0) {
                setBillingAccount(null);
                setAttachBillingAccount(true);
              } else {
                setBillingAccount(res[0]);
                //console.debug('Billing Accounts for user:' + userId + ' - ' + JSON.stringify(billingAccount))
                setAttachBillingAccount(false);
              }
            },
            (err) => {
              console.error(
                "Error fetching Billing Accounts for user:" +
                userId +
                " - " +
                JSON.stringify(err)
              );
            }
          );
        }

        return res;
      });
    } else if (refId) {
      api.referrals.getById(parseInt(refId)).then((res) => {
        setReferral(res);
      });
    }
  }

  const changePaymentType = () => {
    //console.debug (' Payment Type')
    const data = {
      paymentType: PAYMENT_TYPE.SELF
    };

    if (memberService?.id && memberService?.appointment) {
      api.appointments.updateStatus(memberService.appointment.id, {
        status: APPOINTMENT_STATUS.RESCHEDULED
      });
      api.memberEvents.createInsuranceDeniedEvent(memberService.id);
      api.memberServices.updatePaymentType(memberService.id, data).then(
        (res) => {
          console.debug("api.memberServices");
          setShowPaymentTypeChangeConfirmDlg(false);
        },
        (error) => {
          console.error("Error occurred while updating paymentType");
        }
      );
    }
  };

  const cancelPaymentDlg = () => {
    setShowPaymentTypeChangeConfirmDlg(false);
  };

  const chargeMisc = () => {

    console.debug ('chargeMisc')
    //setDisableCopay(true);

    //const defaultBtnTxt = copayBtnText;
    //setCopayBtnText("Charging copay ...");

    const data = {
      memberServiceId: memberService!.id,
      amount: miscAmount ? miscAmount : 0,
      descp: miscAmountDescp
    };

    if ((miscAmount) && (miscAmount > 0)) {
      api.memberServices.chargeMisc(data).then(
        (res) => {
          //console.debug ('Copay Successfull')
          setMiscpayMessage(
            `An amount of $ ${miscAmount} was charged successfully!`
          );
          showAlert (setDisplayMiscPayAlert, 9000)
          _fetchMemberService()
        },
        (err) => {
          console.error(`Payment Charge Failed ${JSON.stringify(Object.keys(err))}`);
          setMiscpayMessage(
            `Error charging $ ${miscAmount}! ${err.response.data}`
          )

          showAlert (setDisplayMiscPayAlert, 2000)
          //setDisplaySpinner (false)
          //setSpinnerMessage('')
          //setDisableCopay(false);
        }
      );
      //setCopayBtnText(defaultBtnTxt);
    }
  };

  const chargeCopay = () => {
    setDisableCopay(true);
    const paymentLog = deepMemberService?.paymentLogs?.find(
      (log) => log.mode === "insurance-copay"
    );

    if (paymentLog) {
      setCopayMessage("A Copay amount has already been processed earlier.");
      showCopayAlert();
      setDisableCopay(false);
      return;
    }
    const defaultBtnTxt = copayBtnText;
    setCopayBtnText("Charging copay ...");

    const data = {
      memberServiceId: memberService!.id,
      amount: copayAmount
    };

    api.memberServices.chargeCopay(data).then(
      (res) => {
        //console.debug ('Copay Successfull')
        setCopayMessage(
          "Copay amount of $" + copayAmount + " was charged successfully"
        );
        showCopayAlert();
      },
      (res) => {
        console.error("Copay Failed");
        //setDisplaySpinner (false)
        //setSpinnerMessage('')
        setDisableCopay(false);
      }
    );
    setCopayBtnText(defaultBtnTxt);
  };

  const _disableCopay = () => {
    if (disableCopay === true) {
      //console.debug ('Disabling Copay button disableCopay = true')
      return true;
    }

    if (deepMemberService?.paymentType === PAYMENT_TYPE.INSURANCE) {
      if (
        [APPOINTMENT_STATUS.CONFIRMED, APPOINTMENT_STATUS.DUE].includes(
          deepMemberService!.appointment!.status
        ) === true
      ) {
        // Check if copay logs exists in payment logs
        const paymentLog = paymentLogs?.find(
          (log) => log.mode === "insurance-copay"
        );
        if (paymentLog) return true;
        else return false;
      } else {
        return true;
      }
    } else {
      //console.debug ('Disabling Copay button paymentType != PAYMENT_TYPE.INSURANCE')
      return true;
    }
  };

  const showCopayAlert = () => {
    setDisplayCopayAlert(true);
    setTimeout(() => {
      setDisplayCopayAlert(false);
    }, 2000);
  };

  // const showEmployerUpdateAlert = () => {
  //   setDisplayEmployerUpdate(true);
  //   setTimeout(() => {
  //     setDisplayEmployerUpdate(false);
  //   }, 2000);
  // };

  const showAdminNoteAlert = () => {
    setDisplayNoteSaveMessage(true);
    setTimeout(() => {
      setDisplayNoteSaveMessage(false);
    }, 2000);
  };

  const dateToString = (date: string | undefined) => {
    //console.debug ('Appt Date:' + JSON.stringify(date))

    if (!date) return "N/A";
    const _date = new Date(date);
    if (_date) return _date.toString();
  };

  const navigate = useNavigate();
  const scheduleNewSession = () => {
    navigate(`/newsession/${memberService?.id}`);
  };

  useEffect(() => { }, [_deepMemberService, deepMemberService]);

  const showBillingAccountModal = () => {
    console.debug("Attaching billing account");
    api.accounts.fetchAllAccounts({ populate: ["users"] }).then(
      (res) => {
        //console.debug('All Billing Accounts - ' + JSON.stringify(res))
        setAllBillingAccounts(res);
        setShowBillingAccounts(true);
      },
      (err) => {
        console.error(
          "ERROR fetching Billing Accounts - " + JSON.stringify(err)
        );
      }
    );
  };

  const updateBillingAccount = (attach: boolean) => {
    const users = [];

    if (attach === true) {
      allBillingAccounts.forEach((account) => {
        //console.debug('Account - ' + JSON.stringify(account))
        if (account?.id === selectedBillingAccount) {
          account?.users?.forEach((user) => {
            users.push(user?.id);
          });
        }
      });
      users.push(memberService?.user?.id);
    } else if (attach === false) {
      allBillingAccounts.forEach((account) => {
        //console.debug('Account - ' + JSON.stringify(account))
        if (account?.id === selectedBillingAccount) {
          account?.users?.forEach((user) => {
            if (user?.id !== memberService?.user?.id) users.push(user?.id);
          });
        }
      });
    }

    let data;

    let billingAccountId;

    if (selectedBillingAccount) {
      billingAccountId = selectedBillingAccount;
    } else {
      console.debug("Use account id from member service");
      billingAccountId = billingAccount?.id;
    }
    console.debug("selectBillingAccount " + JSON.stringify(billingAccountId));

    {
      data = {
        users: users
      };
      api.accounts.update(billingAccountId!, data).then(
        (res) => {
          console.debug("Update Success - ");
          if (attach === false) setSelectedBillingAccount(undefined);
          else setBillingAccount(res);
          setShowBillingAccounts(false);
        },
        (err) => {
          console.error("Update ERROR");
        }
      );
    }
  };

  return (
    <>
      <PageTitle
        title={
          memberService
            ? `Member Service Details - ${memberService?.serviceType?.name}`
            : "Member Referral Details "
        }
      ></PageTitle>
      <Tabs
        defaultActiveKey="home"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="home" title="Member Details">
          <Row>
            {memberService ? (
              <Col>
                {/*** Card for Member Details ***/}

                <Card>
                  <Card.Header className={"text-bg-secondary"}>
                    Member Details:
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      {memberService?.user ? (
                        <MemberProfileForm
                          mode={"admin"}
                          user={memberService?.user}
                          display={() => { }}
                        />
                      ) : null}
                    </Row>
                    {/* <Row>
                                        <Col className="d-flex">
                                            <p className="px-1 text-secondary " style={{ fontSize: "medium" }}>First Name:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "medium" }}>{memberService?.user?.firstName}</p>                                       
                                        </Col>
                                        <Col className="d-flex">
                                            <p className="px-1 text-secondary " style={{ fontSize: "medium" }}>Last Name:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "medium" }}>{memberService?.user?.lastName}</p>                                       
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col className="d-flex">
                                            <p className="px-1 text-secondary " style={{ fontSize: "medium" }}>DoB:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "medium" }}>{memberService?.user?.dateOfBirth}</p>
                                        </Col>
                                        <Col className="d-flex">
                                            <p className="px-1 text-secondary " style={{ fontSize: "medium" }}>State:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "medium" }}>{memberService?.user?.state?.name}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex">
                                            <p className="px-1 text-secondary " style={{ fontSize: "medium" }}>Phone:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "medium" }}>{memberService?.user?.phoneNumber}</p>
                                        </Col>
                                        <Col className="d-flex">
                                            <p className="px-1 text-secondary " style={{ fontSize: "medium" }}>Email:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "medium" }}>{memberService?.user?.email}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex">
                                            <p className="px-1 text-secondary " style={{ fontSize: "medium" }}>Spoken Language:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "medium" }}>
                                                {memberService?.user?.spokenLanguage === 'es' ? 'Spanish' : 'English'}</p>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col className="d-flex">
                                            <Form onSubmit={onUpdateEmployer}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label >Employer: {user?.employer}</Form.Label>
                                                    <Form.Control type="text" placeholder="Employer" id="employer" value={employer}
                                                    onChange={onChangeEmployer} />
                                                </Form.Group>
                                                <Button variant="primary" type="submit">
                                                    Update Employer
                                                </Button>
                                            </Form>
                                        </Col>
                                        <Row>

                                        <Col className="d-flex">
                                        <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displayEmployerUpdate} >
                                            {employerUpdateMessage}
                                        </Alert>
                                        </Col>
                                        </Row>
                                    </Row> */}
                  </Card.Body>
                </Card>
                {memberService?.user?.id ? (
                  <MemberSettings userId={memberService!.user!.id} />
                ) : null}
              </Col>
            ) : null}

            <Col>
              {/*** Card for Referral Details ***/}
              <Card className="flex-fill">
                <CardHeader className={"text-bg-secondary"}>
                  Referral Details:
                </CardHeader>
                <Card.Body>
                  {referral?.clinic ? (
                    <>
                      <Row>
                        <Col className="d-flex">
                          {/* <Form.Text>Referring Clinic: {referral?.clinic?.name}</Form.Text> */}
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "medium" }}
                          >
                            Referring Clinic:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "medium" }}
                          >
                            {referral?.clinic?.name}
                          </p>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  {referral?.serviceProvider ? (
                    <>
                      <Row>
                        <Col className="d-flex">
                          {/* <Form.Text>Referring Clinic: {referral?.clinic?.name}</Form.Text> */}
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "medium" }}
                          >
                            Referring Service Provider:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "medium" }}
                          >
                            {" "}
                            {referral?.serviceProvider?.user?.firstName}{" "}
                            {referral?.serviceProvider?.user?.lastName}
                          </p>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row>
                    <Col className="d-flex">
                      {/* <Form.Text>Referral Notes: {referral?.notes}</Form.Text> */}
                      <p
                        className="px-1 text-secondary "
                        style={{ fontSize: "medium" }}
                      >
                        Referral Notes:
                      </p>
                      <p
                        className="px-1 text-primary"
                        style={{ fontSize: "medium" }}
                      >
                        {referral?.notes}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex">
                      {/* <Form.Text>Referring Doctor: {referral?.notes}</Form.Text> */}
                      <p
                        className="px-1 text-secondary "
                        style={{ fontSize: "medium" }}
                      >
                        Referral Doctor:
                      </p>
                      <p
                        className="px-1 text-primary"
                        style={{ fontSize: "medium" }}
                      >
                        {referral?.referringDoctor}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex">
                      <p
                        className="px-1 text-secondary "
                        style={{ fontSize: "medium" }}
                      >
                        Employer:
                      </p>
                      <p
                        className="px-1 text-primary"
                        style={{ fontSize: "medium" }}
                      >
                        {referral?.employer}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            {memberService ? (
              <>
                <Col>
                  {/*** Card for Appointment Details ***/}
                  <Card className="flex-fill">
                    <CardHeader className={"text-bg-secondary"}>
                      Appointment Details:
                    </CardHeader>
                    <Card.Body>
                      {appointmenrError === true ? (
                        <Row>
                          <Badge bg="danger">Appointment Error</Badge>
                        </Row>
                      ) : null}
                      <Row>
                        <Col className="d-flex">
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "medium" }}
                          >
                            Service Type:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "medium" }}
                          >
                            {memberService?.serviceType?.name}
                          </p>
                          {/* <Card.Text>{memberService?.user?.firstName}</Card.Text> */}
                        </Col>
                        <Col className="d-flex">
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "medium" }}
                          >
                            Service Status:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "medium" }}
                          >
                            {memberService?.status}
                          </p>
                          {/* <Card.Text>Last Name: {memberService?.user?.lastName}</Card.Text> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex">
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "medium" }}
                          >
                            Appointment Date:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "medium" }}
                          >
                            {dateToString(
                              memberService?.appointment?.timeSlot?.startDate
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex">
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "medium" }}
                          >
                            Service Provider:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "medium" }}
                          >
                            {serviceProvider
                              ? serviceProvider?.user?.firstName +
                              " " +
                              serviceProvider?.user?.lastName
                              : "Not Assigned"}
                          </p>
                          {/* <Card.Text>Service Provider: {serviceProvider ? serviceProvider?.user?.firstName + ' ' + serviceProvider?.user?.lastName : "Not Assigned"}</Card.Text> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex">
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "medium" }}
                          >
                            Session Duration:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "medium" }}
                          >
                            {memberService?.sessionDuration} minutes
                          </p>

                          {/* <Card.Text>Session Duration: {memberService?.sessionDuration} </Card.Text> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex">
                          <ButtonToolbar>
                            <ButtonGroup className="m-2, p-1">
                              {/* <Button className="me-2 px-1" onClick={() => scheduleNewSession()}>
                                                        <div className="d-flex align-items-center px-1">
                                                            <FeatherIcon icon="trash" />
                                                            <span className="mx-1">Schedule New Session</span>
                                                        </div>
                                                    </Button> */}
                              <Button
                                className="btn btn-sm btn-primary me-2 pe-1"
                                onClick={() => {
                                  scheduleNewSession();
                                }}
                              >
                                <div className="d-flex align-items-center px-1">
                                  <FeatherIcon icon="calendar" />
                                  <span className="mx-1">
                                    Schedule New Session
                                  </span>
                                </div>
                              </Button>
                            </ButtonGroup>
                            <ButtonGroup className="m-2, p-1">
                              {deleteButton}
                            </ButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  {/*** Card for Billing Account ***/}
                  <Card className="flex-fill">
                    <CardHeader className={"text-bg-secondary"}>
                      Billing Account
                    </CardHeader>
                    <Card.Body>
                      <Row>
                        {billingAccount ? (
                          <>
                            <Col className="d-flex">
                              <p
                                className="px-1 text-secondary "
                                style={{ fontSize: "medium" }}
                              >
                                Billing Account Name:
                              </p>
                              <p
                                className="px-1 text-primary"
                                style={{ fontSize: "medium" }}
                              >
                                {billingAccount?.name}
                              </p>
                            </Col>
                          </>
                        ) : null}

                        <Button
                          onClick={() => {
                            showBillingAccountModal();
                          }}
                        >
                          {attachBillingAccount === false
                            ? "Change/Remove Billing Account"
                            : "Attach Billing Account"}
                        </Button>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  {/*** Card for Payments and Credit Card ****/}
                  <Card>
                    <CardHeader className={"text-bg-secondary"}>
                      Payment Details:
                    </CardHeader>
                    <Card.Body>
                      {/***  Enable Misc. Charges ***/}

                      <Row className='m-1'>
                        <Row>
                          <Alert
                            variant="secondary"
                            className="mb-0 d-md-block d-sm-none"
                            show={displayMiscPayAlert}                            
                          >
                            {miscpayMessage}
                          </Alert>
                          <Col className="d-flex">
                            <Form.Group className="mb-3">
                              <Form.Text>
                                <strong>Charge Amount ($):</strong>
                              </Form.Text>
                              <Form.Control
                                size="sm"
                                as="input"
                                defaultValue={miscAmount}
                                isInvalid={invalidMiscAmount}
                                onChange={(e: any) => {
                                  console.debug (e.target.value)
                                  if ( !isNaN(Number(e.target.value)) || (!e.target.value)){
                                    setMiscAmount(e.target.value);
                                    setInvalidMiscAmount(false)
                                  }
                                  else{
                                    setInvalidMiscAmount(true)
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="d-flex">
                            <Form.Group className="mb-3">
                              <Form.Text>
                                <strong>Charge Description:</strong>
                              </Form.Text>
                              <Form.Control
                                size="sm"
                                as="input"
                                onChange={(e: any) => {
                                  setMiscAmountDescp(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex">
                            <div className="text-center table-responsive">
                              <Button onClick={chargeMisc}>Charge Member</Button>
                            </div>
                          </Col>
                        </Row>
                      </Row>

                      <Row>
                        <Col className="d-flex">
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "small" }}
                          >
                            Payment Type:
                          </p>
                          <p
                            className="px-1 text-primary"
                            style={{ fontSize: "small" }}
                          >
                            {memberService?.paymentType}
                          </p>

                          {/* <Card.Text>Payment Type: {memberService?.paymentType}</Card.Text> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex">
                          <p
                            className="px-1 text-secondary "
                            style={{ fontSize: "small" }}
                          >
                            Charges:
                          </p>
                          {/* <Card.Subtitle>Charges:</Card.Subtitle> */}
                        </Col>
                      </Row>
                      <Row>
                        {deepMemberService?.paymentLogs &&
                          deepMemberService.paymentLogs.map(
                            (v: PaymentLogType, i: number) => (
                              <Row key={i}>
                                <Col className="d-flex">
                                  <p
                                    className="px-1 text-primary"
                                    style={{ fontSize: "small" }}
                                  >
                                    $
                                    {parseFloat(
                                      (v?.amount / 100).toString()
                                    ).toFixed(2)}
                                  </p>
                                  {/* <Form.Text>${parseFloat((v?.amount / 100).toString()).toFixed(2)}</Form.Text> */}
                                </Col>
                                <Col className="d-flex">
                                  <p
                                    className="px-1 text-primary"
                                    style={{ fontSize: "small" }}
                                  >
                                    {v?.mode}
                                  </p>
                                </Col>
                                <Col className="d-flex">
                                  <p
                                    className="px-1 text-primary"
                                    style={{ fontSize: "small" }}
                                  >
                                    {v?.status}
                                  </p>
                                  {/* <Form.Text>{v?.status}</Form.Text> */}
                                </Col>
                                <Col className="d-flex">
                                  <p
                                    className="px-1 text-primary"
                                    style={{ fontSize: "small" }}
                                  >
                                    {new Date(v?.updatedAt).toString()}
                                  </p>
                                  {/* <Form.Text>{new Date(v?.updatedAt).toString()}</Form.Text> */}
                                </Col>
                                <Col className="d-flex">
                                  <p
                                    className="px-1 text-primary"
                                    style={{ fontSize: "small" }}
                                  >
                                    {v?.paymentMethod?.brand}
                                  </p>
                                  {/* <Form.Text>{v?.paymentMethod?.brand}</Form.Text> */}
                                </Col>
                                <Col className="d-flex">
                                  <p
                                    className="px-1 text-primary"
                                    style={{ fontSize: "small" }}
                                  >
                                    {v?.paymentMethod?.last4}
                                  </p>
                                  {/* <Form.Text>{v?.paymentMethod?.last4}</Form.Text> */}
                                </Col>
                              </Row>
                            )
                          )}
                      </Row>
                      <Row>
                        {user ? (
                          <PaymentMethods
                            userType={"admin"}
                            userId={user?.id}
                          />
                        ) : null}
                        <ConfirmModal
                          show={showPaymentTypeChangeConfirmDlg}
                          loading={false}
                          title={"Confirm Payment Type Change"}
                          message={
                            "Please confirm changing the service to Self Pay"
                          }
                          onClickOk={changePaymentType}
                          onHide={cancelPaymentDlg}
                        />
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            ) : null}
          </Row>

          <br />
          {confirmDelete()}
        </Tab>

        {appointment ? (
          <Tab eventKey="appointment" title="Appointment Transcript(s)">
            <Appointment appointmentId={appointment.id} />
          </Tab>
        ) : null}

        <Tab eventKey="healthInsurance" title="HealthInsurance">
          {user ? (
            <Card.Body>
              {user ? (
                // (<HealthInsuranceForm mode={"edit"} healthInsurance={healthInsurance} user={user} onSubmitForm={onSubmitHealthInsuranceForm} formSubmitStatus={formSubmitStatus} />) :
                <HealthInsuranceForm
                  mode={"edit"}
                  healthInsurance={healthInsurance}
                  user={user}
                  display={() => { }}
                />
              ) : null}
            </Card.Body>
          ) : null}
          <Card.Body>
            {deepMemberService?.appointment ? (
              <>
                <Row>
                  <Col className="d-flex">
                    <ButtonToolbar aria-label="Toolbar with button groups">
                      <ButtonGroup className="me-2" aria-label="First group">
                        <Button
                          disabled={
                            deepMemberService?.paymentType !==
                            PAYMENT_TYPE.INSURANCE ||
                            (![
                              APPOINTMENT_STATUS.CONFIRMED,
                              APPOINTMENT_STATUS.DUE
                            ].includes(
                              deepMemberService!.appointment!.status
                            ) &&
                              memberService?.paymentType !==
                              PAYMENT_TYPE.INSURANCE)
                          }
                          onClick={() =>
                            setShowPaymentTypeChangeConfirmDlg(true)
                          }
                        >
                          Process Insurance Verication Failure
                        </Button>
                      </ButtonGroup>
                      {/* <ButtonGroup className="me-2" aria-label="Copay">
                        <Button onClick={chargeCopay}>{copayBtnText}</Button>
                      </ButtonGroup> */}
                    </ButtonToolbar>
                  </Col>
                </Row>
                {/* <Row>
                  <Col className="d-flex">
                    <Form.Group className="mb-3">
                      <Form.Text>
                        <strong>Copay Amount ($):</strong>
                      </Form.Text>
                      <Form.Control
                        size="sm"
                        as="input"
                        defaultValue={copayAmount}
                        onChange={(e: any) => {
                          setCopayAmount(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Alert
                      variant="secondary"
                      className="mb-0 d-md-block d-sm-none"
                      show={displayCopayAlert}
                    >
                      {copayMessage}
                    </Alert>
                  </Col>
                </Row> */}
              </>
            ) : null}
          </Card.Body>
        </Tab>

        <Tab eventKey="teleHealthLocation" title="TeleHealth Location">
          {user?.state ? (
            <Card>
              <Card.Body>
                <TeleHealthLocationForm
                  mode="edit"
                  teleHealthLocation={teleHealthLocationData!}
                  user={user}
                  display={() => { }}
                />
              </Card.Body>
            </Card>
          ) : null}
        </Tab>

        <Tab eventKey="memberEvents" title="Member Events">
          <Card>
            <Card.Body>
              {memberEvents ? (
                <MemberTimeline
                  memberService={deepMemberService}
                  memberEvents={memberEvents}
                />
              ) : (
                <>
                  <strong> No Events</strong>
                </>
              )}
            </Card.Body>
          </Card>
        </Tab>

        {providerReport ? (
          <Tab eventKey="serviceReports" title="Member Reports">
            <Card>
              <Container>
                <Row>
                  <Col className="d-flex">
                    <Form.Text>
                      <strong>Service Report Status: </strong>
                    </Form.Text>
                  </Col>
                  <Col className="d-flex">
                    <Form.Text>{providerReport?.status}</Form.Text>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex">
                    <Form.Text>
                      <strong>Service Report: </strong>
                    </Form.Text>
                  </Col>
                  {providerReport?.document?.url ? (
                    <Col className="d-flex">
                      <Form.Text>
                        <a
                          href={providerReport?.document?.url}
                          target={"_blank"}
                        >
                          Download Service Report
                        </a>
                      </Form.Text>
                    </Col>
                  ) : (
                    <Col className="d-flex">
                      <Form.Text>Report Not Generated</Form.Text>
                    </Col>
                  )}
                </Row>

                <Row>
                  <Form.Text>
                    <strong>Diagnosis Code(s) :</strong>
                  </Form.Text>
                </Row>
                {providerReport.cpt_codes &&
                  providerReport.cpt_codes.map((v: CPTCodeType, i: number) => (
                    <Row>
                      <Col className="d-flex">
                        <Form.Text>{v.code}</Form.Text>
                      </Col>
                      <Col className="d-flex">
                        <Form.Text>{v.description}</Form.Text>
                      </Col>
                    </Row>
                  ))}
              </Container>
            </Card>
          </Tab>
        ) : null}

        <Tab eventKey="userDetails" title="Admin Notes">
          <Card>
            <Card.Body>
              {/* {memberEvents ? (<MemberTimeline memberService={deepMemberService} memberEvents={memberEvents} />)
                                : (
                                    <>
                                        <strong> No Events</strong>
                                    </>)} */}
              <Container>
                <Row>
                  <Col>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Check
                          ref={target}
                          type={"checkbox"}
                          id="followUp"
                          name="followUp"
                          label={`Follow Up:`}
                          defaultChecked={user?.admin_follow_up}
                          disabled={disableFollowUp}
                          //onChange={e => updateAdminFollowUp(e.target.checked)}
                          onClick={(e) => updateAdminFollowUp(e)}
                        />
                        <Overlay
                          target={target.current}
                          show={showFollowUpTip}
                          placement="left"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              {showFollowUpTipMessage}
                            </Tooltip>
                          )}
                        </Overlay>
                      </Form.Group>

                      {/* <Button type="submit" variant="primary">
                                                Save
                                            </Button> */}
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table
                      columns={adminNotesColumns}
                      data={rows}
                      pageSize={20}
                      isSortable={true}
                      pagination={true}
                      isSearchable={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form onSubmit={saveNote}>
                      <Form.Group className="mb-3">
                        <Form.Label>Admin Note:</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="adminNotes"
                          id="adminNotes"
                        />
                      </Form.Group>
                      {/* <Form.Group className="mb-3">                                                
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id='followUp'
                                                    name = 'followUp'
                                                    label={`Member Follow Up:`}
                                                    defaultChecked={user?.followUp}
                                                />
                                            </Form.Group> */}
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                    </Form>
                    <br />
                    <Alert show={displayNoteSaveMessage}>
                      {noteSubmissionStatus}
                    </Alert>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Tab>

        <Tab eventKey="invoiceComponent" title="Invoice(s)">
          {memberService?.user ? (
            <>
              <Invoice
                user={memberService.user as Partial<UserType>}
                account={null}
              ></Invoice>
            </>
          ) : null}
        </Tab>

        {/*
                <Tab eventKey="invoice" title="Invoice(s)">
                    <Card>
                        <Card.Body>

                            <Container>
                                <Row>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                        <Table
                                            columns={invoiceColumns}
                                            data={invoiceRows}
                                            pageSize={20}
                                            isSortable={true}
                                            pagination={true}
                                            isSearchable={true}
                                        />

                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Tab>
*/}
      </Tabs>
      {/* {user ?
                (<PaymentMethods userType={"admin"} userId={user?.id} />) : null
            }
            <ConfirmModal show={showPaymentTypeChangeConfirmDlg} loading={false} title={'Confirm Payment Type Change'} message={'Please confirm changing the service to Self Pay'} onClickOk={changePaymentType} onHide={cancelPaymentDlg} /> */}

      <Modal
        show={showBillingAccounts}
        onHide={() => {
          setShowBillingAccounts(false);
        }}
      >
        <ModalHeader closeButton>
          <strong>Attach Billing Account</strong>{" "}
        </ModalHeader>

        <ModalBody>
          <>
            {/* {JSON.stringify(billingAccount?.id) } */}
            <Form.Select
              aria-label="AccountList"
              onChange={(e) => {
                console.debug(JSON.stringify(e.target.value));
                if (e?.target?.value)
                  setSelectedBillingAccount(parseInt(e.target.value));
              }}
              defaultValue={billingAccount?.id ? billingAccount.id : ""}
            >
              <option>Select a Billing Account</option>
              {allBillingAccounts?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Select>
            <br />

            <Row>
              <Col>
                <Button
                  onClick={() => {
                    updateBillingAccount(true);
                  }}
                >
                  Update Billing Account
                </Button>
              </Col>
              <Col>
                {billingAccount ? (
                  <div>
                    <Button
                      onClick={() => {
                        updateBillingAccount(false);
                      }}
                    >
                      Remove Billing Account
                    </Button>
                  </div>
                ) : null}
              </Col>
            </Row>
          </>
        </ModalBody>
      </Modal>
    </>
  );
}
