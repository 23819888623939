import {
  AppointmentTranscriptSpeechCadenceType,
  AppointmentTranscriptSummaryType
} from "@/apps/common/types/appointment-types";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";

type Props = {
  summary: AppointmentTranscriptSummaryType;
};

function UserSpeechCadence({
  cadence,
  talkingRatio
}: {
  cadence: AppointmentTranscriptSpeechCadenceType;
  talkingRatio: number;
}) {
  return (
    <Col>
      <h4>{cadence.sender}</h4>
      <dl>
        <dt>Words per minute:</dt>
        <dd>{Math.round(cadence.numberOfWords / (cadence.totalTime / 60))}</dd>
        <dt>Talking Ratio:</dt>
        <dd>{talkingRatio.toFixed(2)}%</dd>
      </dl>
    </Col>
  );
}

export default function TranscriptSpeechCadence({ summary }: Props) {
  if (!summary.speechCadence || summary.speechCadence.length < 2) {
    return;
  }

  const memberCadence = summary.speechCadence![0];
  const providerCadence = summary.speechCadence![1] ?? memberCadence;

  const totalSpokenTime = memberCadence.totalTime + providerCadence.totalTime;
  const memberTalkingRatio = (memberCadence.totalTime / totalSpokenTime) * 100;
  const providerTalkingRatio =
    (providerCadence.totalTime / totalSpokenTime) * 100;

  return (
    <Alert variant="secondary">
      <Row>
        <UserSpeechCadence
          cadence={memberCadence}
          talkingRatio={memberTalkingRatio}
        />
        <UserSpeechCadence
          cadence={providerCadence}
          talkingRatio={providerTalkingRatio}
        />
      </Row>
    </Alert>
  );
}
