import { api } from "@/apps/common/api-client";
import { FormInput, PageTitle, VerticalForm } from "@/apps/common/components/";
import { MemberServiceContext } from "@/apps/common/components/contexts/MemberServiceContext";
import { ServiceType } from "@/apps/common/types/appointment-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequest } from "ahooks";
import { AxiosError } from "axios";
import { useContext, useState, useEffect } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuthStore } from "@/apps/common/store/useAuthStore";

interface ServiceTypeData {
  serviceType: number;
}

type ExistingSessionType = {
  existingSession: number;
  serviceType: ServiceType;
};

export default function ScheduleAppointment() {
  const { refreshMemberService } = useContext(MemberServiceContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [existingSession, setExistingSession] = useState<ExistingSessionType>();
  const [langId, setLangId] = useState<number | undefined>(undefined);

  const { user } = useAuthStore()

  const readyToFetchServiceTypes = () => {
    if (user?.state?.id && langId)
      return true
    else return false
  }

  const { data: serviceTypes } = useRequest(() =>
    api.common.fetchServiceTypes()

    // api.common.fetchAvailableServiceTypes(langId, user?.state?.id).then(
    //   (res) => {
    //     console.debug(`res - ${JSON.stringify(res)}`)
    //     //setReadyList(true)
    //     return res
    //   },
    //   (err) => { console.debug('error') }),
    // {
    //   refreshDeps: [langId, user],
    //   ready: readyToFetchServiceTypes()
    // }
    
  );

  useEffect(() => {
    //console.debug (`state - ${JSON.stringify(user?.spokenLanguage)}`)
    api.common.fetchLanguages().then(
      (res) => {
        const languages = res
        languages.forEach(lang => {
          console.debug(`language ${JSON.stringify(lang)}`)
          if (user?.spokenLanguage === lang.lang) {
            console.debug(`setting language Id ${lang.id}`)
            setLangId(lang.id)
          }
        })
      }
    )

  }, [])

  const schemaResolver = yupResolver(
    yup.object().shape({
      serviceType: yup.number().required("Please select service type")
    })
  );

  const onSubmit = async (formData: ServiceTypeData) => {
    try {
      setLoading(true);
      const data = {
        serviceType: formData.serviceType
      };
      const response = await api.memberServices.startNewSession(data);
      await refreshMemberService();

      navigate({
        pathname: "/dashboard",
        search: "?memberServiceId=" + response.id
      });

      setLoading(false);
    } catch (err) {
      const responseData = ((err as AxiosError).response?.data as any)?.error
        ?.details;
      const existingSessionId = responseData?.existingSession as number;

      if (existingSessionId) {
        setExistingSession({
          existingSession: existingSessionId,
          serviceType: serviceTypes?.find(
            ({ id }) => id === formData.serviceType
          ) as ServiceType
        });
      }

      console.warn(err);
      setLoading(false);
    }
  };

  const findServiceTypeIdByName = (serviceName: string) => {
    return serviceTypes?.find(({ name }) => name === serviceName)?.id ?? "";
  };

  const defaultServiceTypeId = findServiceTypeIdByName(
    "Counseling and Therapy for Mental Health"
  );

  let mainComponent;
  if (existingSession) {

    navigate({
      pathname: "/dashboard",
      search: "?memberServiceId=" + existingSession.existingSession
    });

    // mainComponent = (
    //   <div className="p-3 d-flex flex-column text-center">
    //     <p>
    //       You already have started a session for{" "}
    //       <strong>{existingSession.serviceType.name}</strong>. Please finish
    //       that session first before creating a new session for the same service{" "}
    //       <strong>{existingSession.serviceType.name}</strong>.
    //     </p>
    //     <Button
    //       className="mt-3 d-block"
    //       onClick={() => {
    //         navigate({
    //           pathname: "/dashboard",
    //           search: "?memberServiceId=" + existingSession.existingSession
    //         });
    //       }}
    //     >
    //       OK
    //     </Button>
    //   </div>
    // );
  } else if (serviceTypes?.length) {
    mainComponent = (
      <VerticalForm<ServiceTypeData>
        onSubmit={onSubmit}
        resolver={schemaResolver}
        formClass="authentication-form row"
      >
        <FormInput
          label={"I need (select one of the following)"}
          type="select"
          name="serviceType"
          key="serviceType"
          defaultValue={defaultServiceTypeId}
          containerClass={"mb-3 col-sm-12 col-md-12 col-lg-12"}
        >
          {serviceTypes?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </FormInput>
        <div className="mb-2 text-center d-grid col-12">
          <Button disabled={loading} type="submit">
            {loading ? (
              <Spinner
                style={{
                  height: 20,
                  width: 20,
                  marginTop: 4,
                  fontSize: 10
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </VerticalForm>
    );
  }

  return (
    <>
      <script src="https://www.dwin1.com/108440.js" type="text/javascript" defer={true}></script>
      <PageTitle title={"Schedule Another Session"} />
      <Card>
        <Card.Body>{mainComponent ? mainComponent : <Spinner />}</Card.Body>
      </Card>
    </>
  );
}
