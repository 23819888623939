import { api } from "@/apps/common/api-client";
import { useRequest } from "ahooks";

import PageTitle from "@/apps/common/components/PageTitle";
import Spinner from "@/apps/common/components/Spinner";
import FeatherIcons from "feather-icons-react";
import Markdown from "markdown-to-jsx";
import { useCallback } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import AppointmentDiagnosisAid from "./AppointmentDiagnosisAid";
import TranscriptSpeechCadence from "./TranscriptSpeechCadence";

export default function Appointment({
  appointmentId
}: {
  appointmentId: number;
}) {
  const {
    data: appointment,
    loading,
    refresh: refreshAppointment
  } = useRequest(() => api.appointments.getById(Number(appointmentId)));

  const rateSummary = useCallback(
    async (rating: number) => {
      await api.appointments.rateSummary(
        appointment!.transcriptSummary!.id,
        rating
      );
      refreshAppointment();
    },
    [appointment, refreshAppointment]
  );

  if (!appointment) {
    return <Spinner />;
  }

  return (
    <>
      <PageTitle title={"Appointment Details"} />

      {loading ? <Spinner /> : null}
      {appointment.transcriptSummary?.summary ? (
        <Card>
          <Card.Header
            className={"text-bg-secondary d-flex align-items-center"}
          >
            <strong>Summary</strong>
            <div className="flex-fill" />
            <ButtonGroup className="me-2" aria-label="Rate the summary">
              <Button
                variant="secondary"
                active={appointment.transcriptSummary?.summaryRating === 1}
                onClick={() => rateSummary(1)}
              >
                <FeatherIcons icon={"thumbs-up"} />
              </Button>
              <Button
                variant="secondary"
                active={appointment.transcriptSummary?.summaryRating === -1}
                onClick={() => rateSummary(-1)}
              >
                <FeatherIcons icon={"thumbs-down"} />
              </Button>
            </ButtonGroup>
          </Card.Header>
          <Card.Body>
            <Markdown>{appointment.transcriptSummary?.summary}</Markdown>
          </Card.Body>
        </Card>
      ) : null}
      <Row>
        <Col>
          <Card>
            <Card.Header className={"text-bg-secondary"}>
              <strong>Transcripts</strong>
            </Card.Header>
            <Card.Body style={{ whiteSpace: "pre" }}>
              {appointment.transcriptSummary ? (
                <TranscriptSpeechCadence
                  summary={appointment.transcriptSummary}
                />
              ) : null}

              {appointment.transcriptSummary?.fullTranscript ?? (
                <Alert variant="warning">Transcripts not available</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
        {<AppointmentDiagnosisAid appointmentId={appointmentId} />}
      </Row>
    </>
  );
}
