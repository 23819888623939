//import ReferExistingField from "@/apps/common/pages/NewReferral/ReferExistingField";
import { useMemo } from "react";
import { api } from "../api-client";
import AsyncSelectWidget from "../components/form-widgets/AsyncSelectWidget";
import { defaults } from "lodash-es";

type SchemaProps = {
  mode: "create" | "edit" | "preview";
}
function isValidUSZip(sZip: string) {
  return /^\d{5}$/.test(sZip);
}
function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

//const portalType = getPortalType();

const getProgressReportSchema = ({ mode }: SchemaProps) => ({
  type: "object",
  // required: [
  //   "presentingProblem",
  //   "subjectiveObservations",
  //   "objectiveFindings",
  //   "progressAssessment",
  //   "plan",
  // ],
  properties: {
    presentingProblem: {
      type: "string",
      title: "Presenting Problem",
      description: "Please enter the presenting problem"
    },
    subjectiveObservations: {
      type: "string",
      title: "Subjective Observations",
      description: ""
    },
    objectiveFindings: {
      type: "string",
      title: "Objective Findings",
      description: ""
    },
    progressAssessment: {
      type: "string",
      title: "Assessment of Progress",
      description: ""
    },
    plan: {
      type: "string",
      title: "Plan for next time",
      description: "",
    },

  }
});

const getProgressReportUISchema = ({
  mode,
}: SchemaProps) => ({

  "ui:submitButtonOptions": {
    norender: mode === "preview",
    "submitText": "Save"
  },

  "ui:layoutOptions": { gridColumnProps: { xs: 12 } },

  presentingProblem: {
    "ui:widget": "textarea",
    "ui:options": {
      placeholder: "Presenting Problem"
    },

    "ui:readonly": iif(mode === "preview", true)

  },

  subjectiveObservations: {
    "ui:widget": "textarea",
    "ui:options": {
      placeholder: "Subjective Observations"
    },
    "ui:readonly": iif(mode === "preview", true)
  },

  objectiveFindings: {
    "ui:widget": "textarea",
    "ui:options": {
      placeholder: "Objective Findings"
    },
    "ui:readonly": iif(mode === "preview", true)
  },

  progressAssessment: {
    "ui:widget": "textarea",
    "ui:options": {
      placeholder: "Assessment of Progress"
    }
  },

  plan: {
    "ui:widget": "textarea",
    "ui:options": {
      placeholder: "Plan for next time"
    },
    "ui:readonly": iif(mode === "preview", true),
  },

});

function customValidate(formData: any, errors: any) {
  //   if (!isValidDateFormat(formData?.dateOfBirth, DATE_FORMATS.DATE_ONLY)) {
  //     errors.dateOfBirth.addError("Please enter date in MM/DD/YYYY");
  //   }
  // if ( !isValidUSZip(formData?.zip) ){
  //   errors.zip.addError ("Pleaser enter a valid 5 digit US Zip Code")
  // }

  //console.debug ('errors ' + JSON.stringify(errors))
  return errors;
}

function transformErrors(errors: any[]) {
  return errors.map((error) => {
    return error;
  });
}

export function useProgressReportFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
  });


  const schema = useMemo(
    () => getProgressReportSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getProgressReportUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}