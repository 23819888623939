import { Col, Row, Modal, ModalHeader, Container } from "react-bootstrap";
import { api } from "@/apps/common/api-client";
import { useEffect, useState, useMemo } from "react";
import { useRequest } from "ahooks";
import { ServiceProviderType } from "@/apps/common/types/appointment-types";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import Table from "@/apps/common/components/Table";
import API_BASE_URL from "@/config"
import { useNavigate } from "react-router-dom";
import userAvatar from "/src/assets/images/users/avatar-11.png"
import ProviderProfileForm from "@/apps/common/components/ProviderProfile"


// components
import PageTitle from "@/apps/common/components/PageTitle";
//import MemberServiceDetails from "../../components/MemberServiceDetails/MemberServiceDetails";

export default function ServiceProviders() {

    //let showTable = false;
    type rowData = {
        id: number
        createdAt: string
        name: string
        email: string
        phoneNumber: string
        status: string
    }

    const { user } = useAuthStore();
    const [serviceProviders, setServiceProviders] = useState<ServiceProviderType[]>()
    const [displayDetails, setDisplayDetails] = useState<boolean>(false)
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<ServiceProviderType | null>(null)
    const [localDomains, setLocalDomains] = useState<boolean>(false)
    const navigate = useNavigate();

    const handleRowClick = (row: any) => {
        //console.debug ( 'ID '  +  JSON.stringify(row.values.id))
        const serviceProvider = serviceProviders?.find((x) => {
            return (x.id == row.values.id)

        })
        //console.debug ('selectedMemberService -' + JSON.stringify(memberService))
        setSelectedServiceProvider(serviceProvider!)
        setDisplayDetails(true)
        //navigate(`/memberServiceDetails/${memberService?.id}`)
    };

    const columns = [
        {
            Header: "Id",
            accessor: "id",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.id}
                </a>
            )
        },
        {
            Header: "Signup Date",
            accessor: "createdAt",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.createdAt}
                </a>
            )
        },
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.name}
                </a>
            )
        },
        {
            Header: "email",
            accessor:
                "email",
            sort: false,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.email}
                </a>
            )
        },
        {
            Header: "phoneNumber",
            accessor:
                "phoneNumber",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.phoneNumber}
                </a>
            )
        },
        {
            Header: "status",
            accessor:
                "status",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.status}
                </a>
            )
        },
    ];

    async function fetchAllServiceProviders() {

        const populate = {
            populate: ["user", "user.profilePicture", "states", "serviceTypes", "service_provider_types", "languages", "user.state"]
        }
        api.serviceProviders.findMany(populate).then((res) => {

            setServiceProviders(res)
            setRows([])
            res.forEach((x) => {

                //console.debug('serviceProvider - ' + JSON.stringify(x?.languages))

                const row = {
                    id: x?.id,
                    createdAt: x?.createdAt ? new Date(x?.createdAt).toDateString() : 'N/A',
                    name: x?.user?.firstName + ' ' + x?.user?.lastName,
                    email: x?.user?.email ? x?.user?.email : "N/A",
                    phoneNumber: x?.user?.phoneNumber ? x?.user?.phoneNumber : "N/A",
                    status: x?.status ? x?.status : "N/A",
                    //appointmentDate: x?.appointment?.timeSlot?.startDate ? new Date(x?.appointment?.timeSlot?.startDate).toString() : "N/A",
                }
                //showTable = true;
                setRows(prev => [...prev, row])
            })
            return res
        })
    }

    const { data: _serviceProviders = [] } = useRequest(
        () =>
            fetchAllServiceProviders(),
        {
            refreshDeps: [selectedServiceProvider],
            debounceWait: 300,
            pollingInterval: 2000000
        }
    );

    useEffect(() => {
        if (API_BASE_URL.API_URL.includes('localhost') ||
            API_BASE_URL.API_URL.includes('dev') ||
            API_BASE_URL.API_URL.includes('beta')) {
            setLocalDomains(true)
        }
    }, [user, serviceProviders]);


    const a: rowData[] = [];
    const [rows, setRows] = useState(a);

    return (
        <>
            <PageTitle
                title={"Service Providers"}
            />
            <div>
                <Table
                    columns={columns}
                    data={rows}
                    pageSize={20}
                    isSortable={true}
                    pagination={true}
                    isSearchable={true}
                />
            </div>
            <Modal show={displayDetails} onHide={() => { setDisplayDetails(false) }}>
                {/* <ModalHeader closeButton>{selectedServiceProvider?.user?.firstName} {selectedServiceProvider?.user?.firstName}</ModalHeader> */}
                <ModalHeader closeButton><strong>Edit / Update Profile</strong></ModalHeader>
                <div className="text-center mt-2">
                    <div>
                        <img
                            src={selectedServiceProvider?.user?.profilePicture?.url ? selectedServiceProvider?.user?.profilePicture?.url : userAvatar}
                            alt=""
                            className="avatar-lg rounded-circle" /*onClick={handleFileSelect}*/
                        />
                        <br />
                    </div>
                </div>

                <Modal.Body>

                    <Row>
                        {/* <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Phone Number:</p>
                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{selectedServiceProvider?.user?.phoneNumber}</p>
                        </Col>
                        <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Email:</p>
                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{selectedServiceProvider?.user?.email}</p>
                        </Col> */}
                    </Row>
                    <Row>
                        {/* <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Service Provider Type:</p>
                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{selectedServiceProvider?.type}</p>
                        </Col>
                        <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Qualification(s):</p>
                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{selectedServiceProvider?.qualification ? selectedServiceProvider?.qualification : "None Provided"}</p>
                        </Col> */}
                    </Row>
                    <Row>
                        {/* <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Status:</p>
                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{selectedServiceProvider?.status}</p>
                        </Col> */}
                        {/* <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Referral Fees(s):</p>
                            <p className="px-1 text-primary" style={{ fontSize: "small" }}> $ {selectedServiceProvider?.referralFees ? selectedServiceProvider?.referralFees / 100 : 0.0}</p>
                        </Col> */}
                    </Row>
                    {/* <Row>
                        <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Licensed States:</p>
                        </Col>
                    </Row> */}
                    {/* <Row>
                        
                            <Col className="d-flex">
                            <Container fluid>
                                {selectedServiceProvider?.states &&
                                    selectedServiceProvider?.states.map((v: any, i: number) => (
                                        <>
                                            <Row className="text-primary" style={{ fontSize: "small" }}>
                                            {v.name}
                                            </Row>
                                        </>

                                    ))}
                            </Container>
                            </Col>
                        </Row> */}

                    {/* <Row>
                        <Col className="d-flex">
                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Service Types:</p>
                        </Col>
                    </Row> */}
                    {/* <Row>
                    <Col className="d-flex">
                    <Container fluid>
                        {selectedServiceProvider?.serviceTypes &&
                            selectedServiceProvider?.serviceTypes.map((v: any, i: number) => (
                            // <p className="text-primary" style={{ fontSize: "small" }}>{v.name}</p>
                            <Row className="text-primary" style={{ fontSize: "small" }}>
                                {v.name}
                            </Row>
                            
                    ))}
                    </Container>
   </Col>
                    </Row>   */}
                    {selectedServiceProvider ? (
                        <ProviderProfileForm mode={"admin"} serviceProvider={selectedServiceProvider} updateData={setSelectedServiceProvider} providerProfileFormData={() => { }} />
                    ) : null
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}


