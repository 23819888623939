import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany } from "../../helpers/strapi";
import { AdminNoteType } from "../../types/appointment-types";

export class ProgressReportApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  create(data: any) {

    return this.client
      .post("/api/progress-reports", { data })
      .then((res) => res.data);
  }

  // async findAllNotes(userId: string) : Promise<AdminNoteType[]>{

  //   return await this.client
  //     .get(`api/admin-notes`, {
  //       params: {
  //         filters:{
  //         user: userId
  //         },
  //         sort: "createdAt:DESC"
  //       }
  //     })
  //     .then((res) =>        
  //       unstrapiMany<AdminNoteType>(res.data, "admin_note")
  //     );

  // }

  async update(id: number, data: any) {
    return await this.client.put(`api/progress-reports/${id}`, { data });
  }

}
