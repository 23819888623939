import clsx from "clsx";
import FeatherIcon from "feather-icons-react";
import Markdown from "markdown-to-jsx";
import { useContext, useMemo, useState } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import classes from "./MarkdownModal.module.css";
import {
  Accordion,
  Button,
  Form,
  Modal,
  Spinner,
  useAccordionButton,
} from "react-bootstrap";

type AccordionTitleProps = {
  type?: string;
  children: React.ReactNode;
  eventKey: string;
};

function AccordionTitle({ children, eventKey }: AccordionTitleProps) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);

  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  return (
    <Button
      variant="link"
      size="lg"
      className={clsx({
        [classes.accordionTitle]: true,
      })}
      onClick={decoratedOnClick}
    >
      <FeatherIcon
        className={clsx({
          [classes.accordionIcon]: true,
          [classes.accordionIconActive]: isCurrentEventKey,
        })}
        icon="chevron-right"
      />
      {children}
    </Button>
  );
}

interface MarkdownModalProps {
  title: string;
  subtitle?: string;
  content: string;
  acceptText: string;
  type?: "consent" | "coaching-consent";
  show: boolean;
  loading: boolean;
  onAccept: () => void;
  onHide?: () => void;
}

const MarkdownModal = ({
  title,
  subtitle,
  content,
  acceptText,
  type,
  show,
  onAccept,
  loading,
  onHide
}: MarkdownModalProps) => {
  const [acceptance, setAcceptance] = useState(false);

  const consentTemplates = useMemo(() => {
    return content?.split("--").map((x) => x.trim()) ?? [];
  }, [content]);

  //console.debug ('Consent Type = ' + type)
  return (
    <Modal
      className={classes.markdownModal}
      show={show}
      size="lg"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton={!!onHide}>
        <div>
          <h3 className="modal-title block">{title}</h3>
          {subtitle ? (
            <h5 className="modal-subtitle block">{subtitle}</h5>
          ) : null}
        </div>
      </Modal.Header>
      {content ? (
        <>
          <Modal.Body className={classes.markdownModalBody}>
            {type === "consent" ? (
              <>
                <div className="h4 text-danger">View All terms for consent</div>
                <Accordion alwaysOpen>
                  <AccordionTitle eventKey="consent">
                    Consent for Tele-Health Services, Assignment of Benefits and Financial Responsibility
                  </AccordionTitle>

                  <Accordion.Collapse eventKey="consent">
                    <Markdown className={classes.accordionContent}>
                      {consentTemplates[0]}
                    </Markdown>
                  </Accordion.Collapse>
                  <AccordionTitle eventKey="disclosure">
                    Disclosure of record
                  </AccordionTitle>
                  <Accordion.Collapse eventKey="disclosure">
                    <Markdown className={classes.accordionContent}>
                      {consentTemplates[1]}
                    </Markdown>
                  </Accordion.Collapse>
                </Accordion>
              </>
            ) :
              (type === "coaching-consent") ? 
              (
                <>
                <div className="h4 text-danger">View All terms for consent</div>
                <Accordion alwaysOpen>
                  <AccordionTitle eventKey="consent">
                  Coaching Consent Agreement
                  </AccordionTitle>

                  <Accordion.Collapse eventKey="consent">
                    <Markdown className={classes.accordionContent}>
                      {consentTemplates[0]}
                    </Markdown>
                  </Accordion.Collapse>
                  <AccordionTitle eventKey="disclosure">
                    Disclosure of record
                  </AccordionTitle>
                  <Accordion.Collapse eventKey="disclosure">
                    <Markdown className={classes.accordionContent}>
                      {consentTemplates[1]}
                    </Markdown>
                  </Accordion.Collapse>
                </Accordion>
              </>
            
            ) :
            (<Markdown className={classes.markdownContent}>{content}</Markdown>)
            }
            <Form.Check
              id="markdown-modal-acceptance"
              name="acceptance"
              label={acceptText}
              type="checkbox"
              checked={acceptance}
              onChange={(e) => setAcceptance(e.target.checked)}
              className={classes.acceptanceCheckbox}
            />
            <Button
              disabled={loading || !acceptance}
              onClick={onAccept}
              className="w-100"
            >
              {loading ? "loading..." : "Accept"}
            </Button>
          </Modal.Body>
        </>
      ) : (
        <div className="text-center p-4 mt-4 mb-4">
          <Spinner
            style={{ width: 100, height: 100 }}
            animation="border"
            variant="info"
          />
        </div>
      )}
    </Modal>
  );
};

export default MarkdownModal;
