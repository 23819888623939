import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
//import { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
//import { useNavigate } from "react-router-dom";
import { api } from "../api-client";
//import { ProgressReportData } from "../api-client/modules/common.module";
import { useProgressReportFormConfig } from "../forms/ProgressReport";
// import {
//   formatDateOfBirth,
//   reverseFormatDateOfBirth
// } from "../../helpers/date";
//import { useAuthStore } from "../../store/useAuthStore";
import { AppointmentType, ProgressReportType } from "../types/appointment-types";
//import MemberService from "@/apps/admin/pages/MemberService/MemberService";

const Form = generateForm<ProgressReportData>();
export interface ProgressReportData {
  id?: number;
  presentingProblem: string;
  subjectiveObservations: string;
  objectiveFindings: string;
  progressAssessment?: string;
  plan: string;
}
export default function ProgressReportForm({
  mode,
  //teleHealthLocation,
  appointment,
  //progressReport,
  //display,
}: {
  mode: "create" | "edit" | "preview";
  //progressReport: Partial<ProgressReportData> | null
  //teleHealthLocation: TelehealthLocationType;
  //user: Partial<UserType> | null | undefined
  //display: (value: boolean) => void;
  appointment: Partial<AppointmentType>
}) {

  //console.debug ('teleHealthLocation Props- ' + JSON.stringify(teleHealthLocation))
  //const navigate = useNavigate();
  //const { user } = useAuthStore();
  // console.debug ('User - ' + JSON.stringify(user))
  //

  const [submitMessage, setSubmitMessage] = useState<string>();
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [progressReportFormData, setProgressReportFormData] = useState<any>();
  //const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showAlert = () => {

    setDisplaySubmitMessage(true)
    const timeoutId = setTimeout(() => {
      setDisplaySubmitMessage(false);
    }, 2000);

  }

  //console.debug('user?.state?.id ' + user?.state?.id)
  const setValues = () => {
    console.debug(appointment?.progress_report?.notes)
    let progressNote;
    if (appointment?.progress_report?.notes)
      progressNote = JSON.parse(JSON.stringify(appointment?.progress_report?.notes))
    const data = progressNote ? { ...progressNote } : {
      presentingProblem: ''
    }

    // console.debug ('Setting defaultValue ' + JSON.stringify(data))

    return data
  }


  const [defaultValues, setDefaultValues] =
    useState<Partial<ProgressReportData>>();

  useEffect(() => {

    setDefaultValues(setValues())

  }, [appointment]);

  const { schema, uiSchema, customValidate, transformErrors } =
    useProgressReportFormConfig({
      mode,
    });

  const onSubmit = (form: any) => {
    //console.debug ('submitting telehealth location data - ' + JSON.stringify(form.for))

    const formData = form?.formData;
    const progressNotes = JSON.stringify(formData)
    console.debug(`progressNote ${progressNotes}`)

    try {
      setLoading(true);
      if (appointment?.progress_report?.id) {
        // console.debug('Updating an existing form')
        const data = {
          notes: formData
        }

        api.progressReport.update(appointment?.progress_report?.id, data).then(
          (res) => {
            console.debug(`Updated progress note ${progressNotes}`)
          }
        )

        //     //setShowTeleHealthLocation(false)
        //     setSubmitMessage("Telehealth Location changes were saved successfully !")
        //     display(false)
        //     showAlert()
        //   },
        //     (err) => {
        //       setSubmitMessage("An Error occurred while saving Telehealth Location changes, Please try again.")
        //       showAlert()
        //     });
        // }
        // else {
        //   const _telehealthLoc = {
        //     streetAddress1: formData.streetAddress1,
        //     streetAddress2: formData.streetAddress2 ? formData.streetAddress2 : '',
        //     city: formData.city,
        //     zip: formData.zip,
        //     description: formData.description,
        //     state: formData.state,
        //     user: user?.id
        //   }
        //   api.telehealthLoc.create(_telehealthLoc).then((res) => {

        //     //setShowTeleHealthLocation(false)
        //     setSubmitMessage("Telehealth Location changes were saved successfully !")
        //     display(false)
        //     showAlert()
        //   }, (err) => {
        //     setSubmitMessage("An Error occurred while saving Telehealth Location changes, Please try again.")
        //     showAlert()
        //   })

      }

      setLoading(false);

    } catch (err) {
      setLoading(false);
      console.log(err);
    }

  };

  console.debug('defaultValues -' + JSON.stringify(defaultValues))

  return (
    <>
      {defaultValues ? (
        <>
          <br />
          <br />
          <Form
            //formContext={formContext}
            formData={defaultValues as ProgressReportData}
            schema={schema as any}
            uiSchema={uiSchema as any}
            validator={validator}
            showErrorList={false}
            customValidate={customValidate}
            noHtml5Validate
            transformErrors={transformErrors}
            onSubmit={onSubmit}
          ></Form>
          {/* <ConfirmReferralModal
        show={showConfirmModal}
        referralDetails={referralFormData}
        onDismiss={() => setShowConfirmModal(false)}
        onSubmit={submitReferral}
        loading={loading}
      /> */}
          <br />
          <br />
          <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displaySubmitMessage} >
            {submitMessage}
          </Alert>
        </>
      ) : null
      }
    </>

  );

}
