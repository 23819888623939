import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
import { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { useCallback, useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../api-client";
import { ReferralData } from "../../api-client/modules/common.module";
import { useReferralFormConfig } from "../../forms/ReferralForm";
import {
  formatDateOfBirth,
  reverseFormatDateOfBirth
} from "../../helpers/date";
import { useAuthStore } from "../../store/useAuthStore";
import { ReferralType } from "../../types/appointment-types";
import { PaymentContext } from "../contexts/PaymentContextProvider";
import ConfirmReferralModal from "./ConfirmReferralModal";
import { showAlert } from "../../helpers/alertUtils";
import { getPortalType, getPartnerName } from "@apps/common/helpers"
import { PortalTypes } from "@/constants/portals";
import { PAYMENT_TYPE } from "../../constants";

const Form = generateForm<ReferralData>();

export default function ReferralForm({
  mode,
  referral,
  skylerClinic,
}: {
  mode: "create" | "edit" | "admin-edit" | "preview";
  referral?: ReferralType;
  skylerClinic?: true | false
}) {

  console.debug('ReferalForm.tsx - skylerClinic ' + skylerClinic)
  const navigate = useNavigate();
  const { hasPaymentAttached } = useContext(PaymentContext);
  const { user } = useAuthStore();

  const [loading, setLoading] = useState(false);
  const [referralFormData, setReferralFormData] = useState<any>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();


  //console.debug ('Referral - ' + JSON.stringify(referral))

  const getDefaultValues = useCallback(
    () => ({
      spokenLanguage: "English",
      state: user?.state?.id,
      // serviceType: serviceTypes[0]?.id,
      paymentType: "clinic",
      serviceProviderType: "licensed-therapist",
      ...(referral
        ? {
          ...referral,
          referralId: referral.id,
          state: referral?.state?.id,
          serviceType: referral?.serviceType?.id,
          clinic: referral?.clinic?.id,
          dateOfBirth: formatDateOfBirth(referral.dateOfBirth),
          spokenLanguage: referral?.spokenLanguage === "es" ? "Spanish" : referral?.spokenLanguage === "english" ? "English" : referral?.spokenLanguage === "en" ? "English" : 'English',
          referringDoctor: referral?.referringDoctor ? referral?.referringDoctor : '',
          employer: referral?.employer ? referral?.employer : ''


        }
        : {})
    }),
    [referral, user?.state?.id]
  );

  const [defaultValues, setDefaultValues] =
    useState<Partial<ReferralData>>(getDefaultValues());

  const { schema, uiSchema, customValidate, transformErrors } =
    useReferralFormConfig({
      mode,
      type: defaultValues.referralId ? "repeat" : "new",
      hasPaymentAttached,
      skylerClinic
    });

  console.debug('Existing Referral - ' + JSON.stringify(referral))

  const selectExistingReferral = (existingReferral?: ReferralType) => {
    if (existingReferral) {
      const referralValues = {
        referralId: existingReferral.id,
        dateOfBirth: formatDateOfBirth(existingReferral.dateOfBirth),
        state: existingReferral.state!.id,
        firstName: existingReferral.firstName,
        lastName: existingReferral.lastName,
        email: existingReferral.email,
        phoneNumber: existingReferral.phoneNumber,
        referringDoctor: existingReferral.referringDoctor,
        serviceType: existingReferral.serviceType!.id,
        spokenLanguage: existingReferral.spokenLanguage,
        paymentType: existingReferral.paymentType,
        serviceProviderType: existingReferral.serviceProviderType,
        notes: existingReferral.notes || undefined
      };
      //console.debug ('Existing Referrsl ' +  JSON.stringify(referralValues))
      setDefaultValues(referralValues);
    } else {
      setDefaultValues(getDefaultValues());
    }
  };

  const onSubmit = ({ formData }: IChangeEvent<ReferralData>) => {
    //console.debug ('Submitting a referral - ' + JSON.stringify(formData))
    formData!.firstName = formData!.firstName.trim();
    formData!.lastName = formData!.lastName.trim();
    formData!.email = formData!.email.trim();

    setReferralFormData(formData);
    setShowConfirmModal(true);
  };

  const submitReferral = async () => {
    try {

      if (referralFormData?.spokenLanguage === "Spanish")
        referralFormData.spokenLanguage = "es"
      else if (referralFormData?.spokenLanguage === "English")
        referralFormData.spokenLanguage = "en"

      const partnerName = getPortalType() === PortalTypes.PARTNER ? getPartnerName() : ''

      switch (partnerName) {
        case "5G Benefits":
          {
            referralFormData.paymentType = PAYMENT_TYPE.FIVEGBENEFITS
          }
          break;
        case "Aither Health":
          {
            referralFormData.paymentType = PAYMENT_TYPE.AITHER
          }
          break;
        case "Connects Benefit":
          {
            referralFormData.paymentType = PAYMENT_TYPE.CONNECTS_BENEFIT
          }
          break;
      }

      setLoading(true);
      if ((referral?.id) && (mode === "admin-edit")) {
        console.debug('Updating referral- ' + JSON.stringify(referralFormData))
        await api.referrals.update(referral?.id, {
          ...referralFormData,
          dateOfBirth: reverseFormatDateOfBirth(referralFormData.dateOfBirth),
          state: Number(referralFormData.state),
          serviceType: Number(referralFormData.serviceType)
        }).then(
          (res) => {
            setAlertMessage('Member Referral was updated successully.')
            showAlert(setDisplayAlert, 2000)
          },
          (err) => {
            console.debug('Error occured while updating Member Referral - ' + referral.id)
            setAlertMessage(`An ERROR occurred while updating the Member Referral - ${referral?.id}. Please try again`)
            showAlert(setDisplayAlert, 2000)
          })
        setLoading(false);
        //navigate("/referrals");
      }
      else {
        console.debug('Creating referral- ' + JSON.stringify(referralFormData))
        await api.referrals.create({
          ...referralFormData,
          dateOfBirth: reverseFormatDateOfBirth(referralFormData.dateOfBirth),
          state: Number(referralFormData.state),
          serviceType: Number(referralFormData.serviceType)
        }).then((res) => { }, (err) => {
          console.debug('Error occured while submitting the form')
        })
        setLoading(false);
        navigate("/referrals");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }

    setShowConfirmModal(false);
  };

  const formContext = {
    setExistingReferral: selectExistingReferral,
    defaultValues
  };

  //console.debug ('defaulltValues ' + JSON.stringify(defaultValues))

  return (
    <>
      <Form
        formContext={formContext}
        formData={defaultValues as ReferralData}
        schema={schema as any}
        uiSchema={uiSchema as any}
        validator={validator}
        showErrorList={false}
        customValidate={customValidate}
        noHtml5Validate
        transformErrors={transformErrors}
        onSubmit={onSubmit}
      >
      </Form>
      <ConfirmReferralModal
        show={showConfirmModal}
        referralDetails={referralFormData}
        onDismiss={() => setShowConfirmModal(false)}
        onSubmit={submitReferral}
        loading={loading}
      />
      <Alert show={displayAlert}>
        {alertMessage}
      </Alert>
    </>
  );
}
