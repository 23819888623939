import { api } from "@/apps/common/api-client";
import { APPOINTMENT_STATUS } from "@/apps/common/constants";
import { getFullName } from "@/apps/common/helpers";
import { formatDate, formatDateOfBirth } from "@/apps/common/helpers/date";
import {
  AppointmentType,
  TelehealthLocationType
} from "@/apps/common/types/appointment-types";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MEMBER_SERVICE_STATUS } from "@/apps/common/constants";

function TeleHealthDetails({ data }: { data: TelehealthLocationType }) {
  return (
    <Row className="mt-3">
      <h4>TeleHealth Details</h4>
      <Col md="auto">
        <h5>Location Description</h5>
        {data.description}
      </Col>
      <Col md="auto">
        <h5>Street Address</h5>
        {data.streetAddress1}
        {data.streetAddress2 ? (
          <>
            <br />
            {data.streetAddress2}
          </>
        ) : null}
      </Col>
      <Col md="auto">
        <h5>City</h5>
        {data.city}
      </Col>
      <Col md="auto">
        <h5>State</h5>
        {data.state?.name}
      </Col>
      <Col md="auto">
        <h5>Zip Code</h5>
        {data.zip}
      </Col>
    </Row>
  );
}

export default function MemberSessionDetails({
  appointment,
  showActions,
  children
}: PropsWithChildren<{
  appointment: AppointmentType;
  showActions?: boolean;
}>) {
  const memberService = appointment.memberService!;
  const user = memberService.user!;
  const service = memberService.serviceType;

  const navigate = useNavigate();

  const appointmentDate = useMemo(() => {
    const timeSlot = appointment.timeSlot;
    return formatDate(timeSlot.startDate);
  }, [appointment]);

  const teleHealthData = user?.telehealth_location;

  const setNoShow = async () => {
    try {
      await api.appointments.respond(
        appointment.id,
        APPOINTMENT_STATUS.NO_SHOW
      );
      navigate("/appointments");
    } catch (err) {
      console.log(err);
    }
  };

  const rescheduleAppointment = useCallback(() => {
    navigate(`/appointments/${appointment.id}/reschedule`);
  }, [appointment.id, navigate]);

  const setAppointmentComplete = async () => {
    try {
      await api.appointments.updateStatus(
        appointment.id,
        {
          status: `${APPOINTMENT_STATUS.COMPLETED}`
        }
      ).then((res) => {

        api.memberServices.updateStatus(appointment?.memberService?.id, {
          status: MEMBER_SERVICE_STATUS.COMPLETE
        }).then((res) => { navigate("/appointments"); })
      }
      )
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Row>
      <Col>
        <Row className="gap-2">
          <Col md="auto">
            <h5>Patient name</h5>
            {getFullName(user)}
          </Col>
          <Col md="auto">
            <h5>Patient DOB</h5>
            {formatDateOfBirth(user.dateOfBirth)}
          </Col>
          <Col md="auto">
            <h5>State</h5>
            {user.state?.name}
          </Col>
          <Col md="auto">
            <h5>Phone Number</h5>
            {user.phoneNumber}
          </Col>
          <Col md="auto">
            <h5>Email</h5>
            {user.email}
          </Col>
          {memberService.clinic?.name ? (
            <Col md="auto">
              <h5>Referred by</h5>
              {memberService.referral?.referringDoctor
                ? `${memberService.referral?.referringDoctor} at `
                : null}
              {memberService.clinic?.name}
            </Col>
          ) : null}
          <Col md="auto">
            <h5>Service type</h5>
            {service?.name}
          </Col>
          <Col md="auto">
            <h5>Language</h5>
            {memberService?.user?.spokenLanguage}
          </Col>
          <Col md="auto">
            <h5>Appointment time</h5>
            {appointmentDate}
          </Col>
        </Row>
        {memberService.referral?.notes ? (
          <Row className="pt-3">
            <h5>Notes for Skyler Health</h5>
            <Col>{memberService.referral?.notes}</Col>
          </Row>
        ) : null}
        <Row className="pt-3">
          {memberService?.user?.telehealth_location ? <TeleHealthDetails data={memberService?.user?.telehealth_location} /> : (
            <>
              No Telehealth Location Data
            </>
          )}
        </Row>
      </Col>
      {showActions ? (
        <Col
          className="px-5 py-3 align-items-center justify-content-center"
          md="auto"
          sm="12"
        >
          <Row>
            <Col>Meeting status: {appointment.meetingStatus}</Col>
          </Row>
          <Row>
            <Col>
              {appointment.meetingJoinLink &&
                [APPOINTMENT_STATUS.CONFIRMED, APPOINTMENT_STATUS.DUE].includes(
                  appointment.status
                ) && (
                  <Button
                    variant="success"
                    href={appointment.meetingJoinLink}
                    target="_blank"
                  >
                    Start meeting
                  </Button>
                )}
              {[APPOINTMENT_STATUS.DUE].includes(appointment.status) ? (
                <Button variant="danger" className="ms-2" onClick={setNoShow}>
                  No Show
                </Button>
              ) : null}
              {[APPOINTMENT_STATUS.CONFIRMED, APPOINTMENT_STATUS.DUE].includes(
                appointment.status
              ) ? (
                <Button
                  variant="warning"
                  className="ms-2"
                  onClick={rescheduleAppointment}
                >
                  Reschedule Appointment
                </Button>
              ) : null}
              {[APPOINTMENT_STATUS.CONFIRMED, APPOINTMENT_STATUS.DUE].includes(
                appointment.status) && (memberService.serviceType?.name === 'Mental Health and Wellness Coaching') ? (
                <Button
                  variant="warning"
                  className="ms-2"
                  onClick={setAppointmentComplete}
                >
                  Complete Appointment
                </Button>
              ) : null}
            </Col>
          </Row>
        </Col>
      ) : null}
      {children}
    </Row>
  );
}
