import { api } from "@/apps/common/api-client";
import { FormInput, VerticalForm } from "@/apps/common/components";
import { useRequest } from "ahooks";
import React from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";

interface UserSettings {
  transcriptionEnabled: boolean;
}

type Props = { userId: number };

export default function MemberSettings({ userId }: Props) {
  const {
    data: settings,
    loading,
    refresh: refreshSettings
  } = useRequest(() => api.userSettings.getByUserId(Number(userId)));

  console.log({ settings });

  const { loading: saving, run: saveSettings } = useRequest(
    (data: UserSettings) => api.userSettings.update(settings!.id, data),
    {
      manual: true
    }
  );

  const handleSubmit = (formData: UserSettings) => {
    console.log(formData["transcriptionEnabled"]);
    saveSettings(formData);
  };

  return (
    <div>
      <Card className="flex-fill">
        <CardHeader className={"text-bg-secondary"}>Settings</CardHeader>
        <Card.Body>
          <VerticalForm<UserSettings>
            onSubmit={handleSubmit}
            formClass="authentication-form row"
          >
            <FormInput
              type="checkbox"
              name="transcriptionEnabled"
              label="Transcription Enabled"
              defaultChecked={settings?.transcriptionEnabled}
            />

            <div className="mt-2">
              <Button
                variant="primary"
                type="submit"
                disabled={loading || saving}
              >
                {loading ? (
                  <Spinner className="me-2" animation="border" size="sm" />
                ) : null}
                Save
              </Button>
            </div>
          </VerticalForm>
        </Card.Body>
      </Card>
    </div>
  );
}
