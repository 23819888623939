import React, { useContext, useEffect, useMemo, useState } from "react";
import PaymentModal from "@/apps/common/components/PaymentModal";
import { useCommonStore } from "@/apps/common/store/useCommonStore";
import { PAYMENT_STATUS, PAYMENT_TYPE } from "@/apps/common/constants";
import { MemberServiceContext } from "../../../common/components/contexts/MemberServiceContext";
import { useRequest } from "ahooks";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { api } from "@/apps/common/api-client";
import { useTranslation } from "react-i18next";
//import HealthInsurance from "../HealthInsurance/HealthInsurance";

 
export default function MemberPayment() {

  console.debug ('Member Payment')

  const { t } = useTranslation();

  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  const { memberService } = useContext(MemberServiceContext);

  const { addPaymentMethod } = useCommonStore();

  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  //const [showHealthInsuranceModal, setShowHealthInsuranceModal] = useState<boolean>(false);
  
  const [showPaymentType, setShowPaymentType] = useState<boolean>(false)
  const [pymntType, setPaymentType] = useState<string>(PAYMENT_TYPE.SELF);

  const addPayment = async () => {
    try {
      console.debug ('Adding new card')
      setPaymentLoading(true);
      const { data } = await addPaymentMethod();
      setShowPaymentModal (false)
      location.replace(data.url);
      setPaymentLoading(false);
      setShowPaymentModal (false)
    } catch (err) {
      console.error(err);
      setPaymentLoading(false);
    }
  };

  const { data: paymentProfile } = useRequest(() =>
    api.payments.getPaymentProfile()
  );

  const paymentMethodStatus = useMemo(() => {
    if (!paymentProfile) {
      return PAYMENT_STATUS.ATTACHED
    }

    return paymentProfile.paymentMethodStatus;
  }, [paymentProfile]);

  const paymentType = memberService!.paymentType;

  useEffect(() => {
    if (
      (paymentType === PAYMENT_TYPE.SELF  || paymentType === PAYMENT_TYPE.INSURANCE )&&
      paymentMethodStatus !== PAYMENT_STATUS.ATTACHED
    ) {
      setShowPaymentModal(true);
    }
  }, [paymentMethodStatus, paymentType]);
  
  return (
    <>
      <PaymentModal
        loading={paymentLoading}
        show={showPaymentModal}
        onClick={addPayment}
        serviceType= {memberService?.serviceType?.name==="Mental Health and Wellness Coaching" ? "coaching" : "therapy"}
        //serviceType={"coaching"}
      />
    </>

  );
}
