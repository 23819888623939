import { AxiosInstance } from "axios";
import { unstrapiMany } from "../../helpers/strapi";
import { AppointmentDiagnosisAidType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

export class AppointmentDiagnosisAidApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api/member-appointment-diagnosis-aids");
  }

  async getByAppointment(appointment: number) {
    return this.client
      .get(this.buildPath(), {
        params: {
          filters: {
            appointment: {
              id: appointment
            }
          },
          sort: "createdAt:DESC",
          pagination: { pageSize: 100 }
        }
      })
      .then((res) =>
        unstrapiMany<AppointmentDiagnosisAidType>(
          res.data,
          "appointment_diagnosis_aid"
        )
      );
  }

  async rateDiagnosis(diagnosisId: number, rating: number) {
    return await this.client.put(this.buildPath(String(diagnosisId)), {
      data: { diagnosesRating: rating }
    });
  }

  async selectDiagnosisCode(
    diagnosisId: number,
    selectedDiagnosisCode: string
  ) {
    return await this.client.put(this.buildPath(String(diagnosisId)), {
      data: { selectedDiagnosisCode }
    });
  }
}
